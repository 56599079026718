<template>
     <!-- tabs -->
    <div class="mb-1rem display-flex-center md:flex-start scroll-from-top">
        <div class="tabs" :class="{'selected': o.selected}" v-for="(o,index) in tabs" :key="index" @click="handleTabs(o)">
            <span class="tabs-name">{{ $t(o.name) }}</span>
            <span class="tabs-line"></span>
        </div>       
    </div>

    <!-- table -->
    <div v-if="currentTabs.id === 1" class="event-table scroll-from-top" >
        <div class="event-table__header">
            <div class="event-table__header-title tc"><span>{{ $t('NICKNAME') }}</span></div>
            <div class="event-table__header-title tc"><span >{{ $t('POINTS') }}</span></div>
        </div>
        <div class="event-table__body">
            <li v-show="timeType !== 3" class="event-table__body-list" v-for="(o, index) in latestPredictionList" :key="index">
                <span class="flex-1 tc ">{{ o.displayName }}</span>
                <span class="flex-1 tc ">{{ o.point }}</span>
                <div class="event-table__body-list__divider"></div>
            </li>       
            <div v-show="timeType === 3" class="event-table__no-data">{{ $t('NO_PREDICTION') }}</div>       
        </div>
        <div class="event-table__footer"></div>
    </div>

    <div v-if="currentTabs.id === 2" class="event-table">
        <div class="event-table__header">
            <div class="event-table__header-title tc"><span>{{ $t('POINTS') }}</span></div>
        </div>
        <div class="event-table__body">
            <li v-show="timeType !== 3" class="event-table__body-list" v-for="(o, index) in myPredictionData.myPredictions" :key="index">
                <span class="flex-1 tc ">{{ o.myPredictionPoint }}</span>
                <div class="event-table__body-list__divider"></div>
            </li>    
            <div v-show="timeType === 3" class="event-table__no-data">{{ $t('NO_PREDICTION') }}</div>    
        </div>   
        <div class="event-table__footer"></div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
    props: {
        contestId: {
            type: Number,
            default: 1
        },
        contestInfo: {
            type: Object,
            default: {}
        }
    },

    data() {
        return {
            tabs: [
                {
                    name: 'LEADERBOARD',
                    id: 1,
                    selected: false
                },
                {
                    name: 'MY_PREDICTIONS',
                    id: 2,
                    selected: false
                },
            ],
            currentTabs: {},

            latestPredictionList: [],
            myPredictionData: {},

        }
    },
    mounted() {
        //this.handleTabs(this.tabs[0]);    
    },
    watch:{
        contestId: {
            deep:true,
	        	handler(newVal, oldVal) {
                    this.handleTabs(this.tabs[0]);   
                    this.getMyPredictionData();
			}
        },
        contestInfo: {
            deep:true,
	        	handler(newVal, oldVal) {
                 this.timeType = newVal.timeType;
			}
        }
    },

    methods: {
        ...mapActions([
            "getLatestPredictionList",
            "getMyPrediction"
        ]),
        
        handleTabs(o) {
            this.tabs.forEach(x => {
                x.selected = false;
            });

            o.selected = true;
            this.currentTabs = o
            
            switch (o.id) {
                case 1:
                    this.getListParticipateData();
                    break;
                case 2:
                    this.getMyPredictionData();
                    break;
            }
        },

        updateLatestTabData() {
            this.handleTabs(this.currentTabs)
        },

        async getListParticipateData() {
            let params = {
                contestId: this.contestId,
            }
    
            const result = await this.getLatestPredictionList(params);

            if (result.result !== null) {
                this.latestPredictionList = result.result.ranking;
            }
        },

        async getMyPredictionData() {
            let params = {
                contestId: this.contestId,
            }

            const result = await this.getMyPrediction(params);

            if (result.result) {
                this.myPredictionData = result.result
            } else {
                this.myPredictionData.myPredictions = [
                    {
                        myPredictionPoint: '-'
                    }
                ]
            }
            
        }

    },
}
</script>

<style scoped>

    /* table */
    .event-table{
        width: 49.625rem;
        border-radius: 0.5rem;
        overflow: hidden;
        margin: 0 auto 5rem;
    }
    .event-table__header{
        background-color: var(--color-theme);
        padding: 0.625rem 5rem;
        display: flex;
    }
    .event-table__header-title{
        flex: 1;
        font-size: 0.875rem;
    }
    .event-table__header-title-name{
        padding-left: 7.5rem;
    }
    .event-table__body{
        font-size: 0.6875rem;
        min-height: 20.5rem;
        background-color: rgb(32 32 32 / 60%);
    }
    .event-table__body-list{
        display: flex;
        padding: 0.65625rem 5rem;
        position: relative;
        justify-content: center;
        align-items: center;
    }
    .event-table__body-list .flex-1{
          width: 50%;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .event-table__body-list__divider{
        position: absolute;
        bottom: 0;
        width: 80%;
        height: .08rem;
        background-color: var(--color-grey-60);
    }
    .event-table__body-list__result{
        margin-right: 2rem;
        display: flex;
        align-items: center;
    }
    .event-table__body-list__result-name{
        width: 7rem;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: 1rem;
        text-align: right;
    }
    .event-table__body-list__result-name-2{
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
        width: 7rem;
    }
    .event-table__body-list__result-score-2{
        width: 1.5rem;
        display: block;
        margin-right: 0.5rem;
    }

    .event-table__footer{
        background-color: var(--color-theme);
        padding: 0.625rem;
        display: flex;
        min-height: 1rem;
        align-items: flex-end;
        justify-content: flex-end;
    }
    .event-table__body-list__child-1{
        flex: 1;
        text-align: center;
        overflow: hidden;
    }
    .event-table__body-list__child-2{
        flex: 1;
        text-align: center;
        align-items: center;
    }
    .event-table__no-data{
        min-height: inherit;
        display: flex;
        justify-content: center;
        align-items: center;
    }


@media (max-width: 768px) {
    .event-table{
            width: 100%;
    }
    /* .event-table__header-title{
        width: 30%;
        flex: none;
    } */
    .event-table__header{
        padding: 0.625rem 2rem;
    }
    .event-table__header-title.title-2{
        width: auto;
        flex: 1;
    }
    .event-table__body-list{
        padding: 0.65625rem 2rem;
    }
    .event-table__body-list .flex-1{
        width: 50%;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .event-table__body-list__child-1{
        width: 30%;
        flex: none;
        text-align: center;
        overflow: hidden;
    }
    .event-table__body-list__child-2{
        width: 70%;
        flex: none;
        text-align: center;
        align-items: center;
    }
    .event-table__body-list__result-name-2{
        width: 5rem;
    }
    .event-table__body-list__result-name{
        width: 5rem;
    }
    .event-table__body-list__result {
        margin-right: 1rem;
    }
    .event-table__header-title-name{
        padding-left: 5.5rem;
    }

}
</style>