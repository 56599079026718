<template>
  <div class="knockout-line__wrapper" :class="mode">
    <div class="knockout-line__horizontal top"></div>
    <div class="knockout-line__horizontal bottom"></div>
    <div class="knockout-line__vertical"></div>
 </div>

</template>

<script>
export default {
    props: {
        mode: {
            type: String,
            default: ''
        }
    }
}
</script>

<style>
    .knockout-line__wrapper{
        position: absolute;
        width: 103px;
        height: 53px;
        top: 10px;
        left: -103px;
    }
    .knockout-line__horizontal{
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: #4B616C;
    }
    .knockout-line__vertical{
        position: absolute;
        width: 2px;
        height: 100%;
        background-color: #4B616C;
    }
    .knockout-line__horizontal.top{
        top: 6px;
    }
    .knockout-line__horizontal.bottom{
        bottom: 6px;
    }
    
    .medium.knockout-line__wrapper{
        width: 72px;
        height: 148px;
        top: 68px;
        left: 67px;
    }
    .medium .top{
        top:54px;
    }
    .medium .bottom{
        bottom:49px; 
    }
</style>