<template>
 <div class="relative">
    <div class="prediction-bg">
        <img v-if="isMobile" class="w-100" src="../../../../static/images/worldcup2022/mobile_background.png">
        <img v-else class="img-cover" src="../../../../static/images/worldcup2022/background.jpg">
    </div>
    <div class="container display-flex flex-col has-space mt-m">
        <div class="mobile-header  mb-05rem" v-if="isMobile">
            <!-- <template v-if="contestInfo.timeType === 1 &&">
                 <MainLogo></MainLogo>
                <div class="tc font-mbold mobile-header__center">{{contestInfo.contestDescription }}</div>
                <div  class="mobile-header__right">
                    </div>
            </template> -->
            <div v-if="knockOutLive.isShow" @click="handleKnockOutSwitchViewMobile"><img class="prediction-header__right-icon" src="../../../../static/images/icons/icon_arrow_left_double_white.png"></div>
            <MainLogo v-else></MainLogo>
            <div class="tc font-mbold mobile-header__center">{{ knockOutLive.isShow ? $t("LIVE_BRACKET"): contestInfo.contestDescription }}</div>
            <div class="mobile-header__right" style="width: 3rem">
                <img v-if="knockOutLive.isShowIcon && !knockOutLive.isShow" @click="handleKnockOutSwitchViewMobile"  class="prediction-header__right-icon" :src="require(`../../../../static/images/icons/icon_bracket_transparent.png`)">
            </div>
          
        </div>
     
        <div v-show="knockoutVerifyToShowLive()" class="prediciton-banner__wrapper" >
            <Banner 
                :type = 2 
                :contestInfo="contestInfo"
            />
        </div>

        <Countdown
            v-show="knockoutVerifyToShowLive()"
            :contestInfo="contestInfo"
            @emitCountdownEvent="receiveCountdownEvent"
        />

        <Title class="scroll-from-top"
            v-show="knockoutVerifyToShowLive()"
            :contestInfo="contestInfo"
            desc="CHOOSE_YOUR_TEAMS_FOR_FIFA_WORLD_CUP_QATAR_2022" 
        />

        <WorldCupKnockout
            ref="WorldCupKnockoutRef"
            :contestId="contestId"
            :isReload="isReload"
            :contestInfo="contestInfo" 
            @emitKnockoutEvent="receiveKnockoutEvent($event)"
        />

        <div v-show="knockoutVerifyToShowLive()" class="prediction-bottom">
            <JoinEvent
                ref="WorldCupJoinEventRef"
                :contestId="contestId" 
                :isBgTransparent="isMobile"
                :contestInfo="contestInfo" 
                :matchKnockOutsList="matchKnockOutsList"
                @emitJoinEvent="receiveJoinEvent($event)"
            />

            <TableTitle 
                class="mt-m"
                :title="'PREDICTION_RECORDS'"
            />

            <TableRecord 
                ref="TableRecordRef"
                :contestInfo="contestInfo" 
                :contestId="contestId" 
            />
        </div>
    </div>
</div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import SwiperCore, { Pagination,Navigation,Autoplay} from 'swiper/core';
SwiperCore.use([Pagination,Navigation,Autoplay]);

import { mapGetters,mapActions } from 'vuex'

import config from '@/js/config.js'

import MainLogo from "@/components/indicator/MainLogo.vue";
import TableRecord from '@/components/event/TableRecord.vue'
import Banner from '@/components/event/Banner.vue'
import Countdown from '@/components/event/Countdown.vue'
import Title from '@/components/event/Title.vue'
import JoinEvent from '@/components/event/JoinEvent.vue'
import TableTitle from '@/components/event/TableTitle.vue'
import WorldCupKnockout from '@/components/event/worldcup/Knockout.vue'

export default {
    components: {
        TableRecord,
        Banner,
        Countdown,
        Title,
        JoinEvent,
        TableTitle,
        WorldCupKnockout,
        MainLogo,
        Swiper,
        SwiperSlide,      
    },
    data() {
        return {
            s3ImgUrl: config.s3ImgUrl,
            currentPathName: '',

            contestId: null,

            contestInfo: {},
            matchKnockOutsList: [],

            isReload: false,  
        }
    },
    watch:{
        currentLocale: {
            deep: true,
            handler(prev, nesw) {
              //this.init(); 
            }
        },
        currentLanguageObj: {
            deep: true,
            handler(newVal, oldVal) {
                if (newVal.displayName === "English") {
                    //this.init();
                }
            }
        },
        currentCountryObj: {
            deep: true,
            handler(prev, nesw) {
              //this.init(); 
            }
        },
         userSwitchCountryLang: {
            deep: true,
            handler(prev, nesw) {
                this.$router.push(`/${this.currentLanguageObj.displayLocale}/event`);  
                //this.init();
            }
        }
   },
    computed: {
        ...mapGetters([              
                'isMobile',
                'currentLocale',
                'currentLanguageObj',
                'isLogin',
                'knockOutLive',
                'currentCountryObj',
                'userSwitchCountryLang'
           ]),
    },

    mounted() {
        this.commonVerifyLogin();
        this.init();
    },
    methods: {
        ...mapActions([
            "getContestInfo",
            "handleContestEndEvent",
            "handleKnockOutEvent",
            "handleEventEnded",
            "getCountryCodeByIp",
            "currentCountryChangeEvent",
            "commonVerifyLogin",
        ]),
        async init() {
            //get contest id
            this.contestId = this.$route.params.contestId;
            this.currentPathName = window.location.pathname;

            if(!this.isLogin && !this.userCountryCode) {
                let result = await this.getCountryCodeByIp();
                if (result.message === "Success") {
                    this.currentCountryChangeEvent(result.result.countryCode);
                }
            }
            this.getContestInfoData();
        },
        
        async getContestInfoData() {
            let params = {
               contestId: this.contestId,
               language: this.currentLanguageObj.locale,
               lang: this.currentLanguageObj.apiParam,
            }

            const result = await this.getContestInfo(params);

            //if there is no contest for current language, will redirect to event (AS-1432) homepage
            if (result.result === null) {       
                this.$router.push(`/${this.currentLanguageObj.displayLocale}/event`);
                return;
            } else {
                this.contestInfo = result.result;
            }

            if (this.contestInfo.timeType === 1) {
                //past event
                this.handleContestEndEvent(true); 
            } else {
                this.handleContestEndEvent(false); 
            }

            //component worldCupKnockout init data
             this.sessionStorageKnockout = JSON.parse(sessionStorage.getItem('worldcupKnockout'));  

            if (this.sessionStorageKnockout === null) {
           
                setTimeout(() => {
                  this.$refs.WorldCupKnockoutRef.init();  
                }, 300);                
            } else {
             
                let routeQuery = this.$route.query;
                let queryObject = {};

                if (Object.keys(routeQuery).length !== 0 && routeQuery.constructor === Object) {
     
                    this.$refs.WorldCupKnockoutRef.init(); 
                    //this.refs.WorldCupJoinEventRef.handleBtnSubmit();
                } else {
        
                    // sessionStorage.removeItem("worldcupKnockout");
                    // this.$refs.WorldCupKnockoutRef.init();  
                }
            }
        
            //this.initKnockOutLive();
            //this.$refs.WorldCupKnockoutRef.init();
        },

        //receive component emit event section
        receiveCountdownEvent() {
            this.init();
        },

        receiveJoinEvent(e) {
            //update latest data for table current tab
            this.$refs.TableRecordRef.updateLatestTabData();
          
            this.$refs.WorldCupKnockoutRef.init();
        },

        receiveKnockoutEvent(e) {
            this.matchKnockOutsList = e;
        },
        
         //knockout live section
        initKnockOutLive() {           
             //Except upcoming(3)
             //Live bracket only appears in Ongoing(2) and Past Event(1)     

            if (this.contestInfo.timeType === 3) {
                //upcoming (3)
                this.handleKnockOutEvent({isShowIcon: false})  
            } else if (this.contestInfo.timeType === 2) {
                //ongoing (2)
                if (this.isLogin) {                   
                    this.handleKnockOutEvent({isShowIcon: true})
                } else {
                    this.handleKnockOutEvent({isShowIcon: false})
                }   
            } else {
                //past event (1)
                if (this.isLogin) {
                    this.handleKnockOutEvent({isShowIcon: true})
                } else {
                    this.handleKnockOutEvent({isShowIcon: false, isShow: true})
                }
            }
        },

        handleKnockOutSwitchViewMobile() {
            this.handleKnockOutEvent({isShow: !this.knockOutLive.isShow})          
        },

        knockoutVerifyToShowLive() {
            if (this.isMobile) {
                 if (!this.knockOutLive.isShow) {
                    return true;
                 } else {
                    return false
                 }             
            } else {
                return true
            }
        }
    },
}
</script>


<style scoped>
    .prediction-bg{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: -1;
    }
    .prediciton-banner__wrapper{
        margin-bottom: 2rem;
    }
    .prediction-header__right-icon{
        width: 1.25rem;
    }
  @media (max-width: 768px){
        .prediciton-banner__wrapper{
            margin-bottom: 1rem;
        }
        .prediction-modal__team-icon__wrapper{
            grid-template-columns: repeat(3,1fr);
        }
        .prediction-team__wrapper{
            width: 100%;
            margin-bottom: 1.5rem;
        }
        .prediction-table{
             width: 100%;
        }
        .prediction-team__choose{
            width: 2.75rem;
            height: 2.75rem;
            padding: 0.3rem;
        }
        .prediction-team__score{
            width: 2.3rem;
            height: 2.3rem;
            margin-top: 1rem;
        }
        .prediction-team__score input{
            font-size: 1.2rem;
        }
         .prediction-modal__team-icon__container.selected{
            border-color: var(--color-hover);
            background-color: var(--color-grey-60);
        }
        .prediction-modal__team-icon__container:hover{
            border-color: var(--color-hover);
            background-color: var(--color-grey-60);
        }
        .prediction-modal__team-icon.selected{
            border-color: transparent;
            background-color: transparent;
        }
        .prediction-modal__team-icon__container:hover .prediction-modal__team-icon{
            border-color: transparent;
            background-color: transparent;
        }
        .prediction-bottom{
            position: relative;
            top: -10.7rem;
        }

    }

</style>