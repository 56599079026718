<template>
    <div class="display-flex-center flex-col">
        <h4 class="event-entry__title tc" >{{ $t(title) }}</h4>
        <div class="event-entry__desc" >{{ contestInfo.question === null ? $t(desc) : contestInfo.question}}</div>  
    </div>
</template>

<script>
export default {
    props: {
        title: {
            default: 'PREDICTION_ENTRY'
        },
        contestInfo: {
            type: Object
        },
        desc: {
            type: String,
            default: null
        }
    }
}
</script>

<style scoped>
.event-entry__title{
    font-size: 1.375rem;
    font-weight: 700;
    margin: 0 0 0.5rem 0;
}
.event-entry__desc{
    font-size: 0.6875rem;
    color: #FFFFFF;
    margin-bottom: 1.1875rem;
}
</style>