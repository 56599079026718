<template>
    <!-- countdown -->
        <div class="prediction-countdown__wrapper scroll-from-top">
            <div class="display-flex-center">
                <div class="prediction-countdown__title" >
                    <span :class="{'prediction-countdown__title-bold': isEventEnded}">{{ isEventEnded ? $t("EVENT_ENDED") : $t(countDownPrefix) }}
                    </span>
                </div>
                <div v-if="!isEventEnded" class="prediction-countdown">
                    <div class="prediction-countdown__time" v-if="eventTime.days !== '00'">
                        <span class="prediction-countdown__time-time">{{ eventTime.days}}</span>
                        <span class="prediction-countdown__time-day">{{ $t('DAYS')}}</span>
                    </div>
                    <div class="prediction-countdown__time-seperator"  v-if="eventTime.days !== '00'"><span>:</span></div>

                    <div class="prediction-countdown__time">
                        <span class="prediction-countdown__time-time">{{ eventTime.hours }}</span>
                        <span class="prediction-countdown__time-day">{{ $t('HRS') }}</span>
                    </div>
                    <div class="prediction-countdown__time-seperator"><span>:</span></div>

                    <div class="prediction-countdown__time">
                        <span class="prediction-countdown__time-time">{{ eventTime.min }}</span>
                        <span class="prediction-countdown__time-day">{{ $t('MINS') }}</span>
                    </div>
                    <div class="prediction-countdown__time-seperator"  v-if="eventTime.days === '00'"><span>:</span></div>

                    <div class="prediction-countdown__time"  v-if="eventTime.days === '00'">
                        <span class="prediction-countdown__time-time">{{ eventTime.sec }}</span>
                        <span class="prediction-countdown__time-day">{{ $t('SECS') }}</span>
                    </div>
                </div>
            </div>
        </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import moment from 'moment'
export default {
    props: {
        contestInfo: {
            type: Object
        }
    },

    data() {
        return {
            eventTime: {
                days: '',
                hours: '',
                min: '',
                sec: ''
            },
            countDownPrefix: '',
         
            timeType: 1
        }   
    },
   watch:{
        contestInfo: {
            deep:true,
	        	handler(newVal,oldVal) {
                    this.timeType = newVal.timeType;
                    let eventEndDate = newVal.endDate;
                    let eventStartDate = newVal.startDate;

                    if (this.timeType === 2) {
                        //ONGOING
                        this.countDownPrefix = "ENDING_IN";  
                        this.updateContestInfoDate(eventEndDate);
                    } else if (this.timeType === 3) {
                        //UPCOMING
                        this.countDownPrefix = "STARTING_IN";
                        this.updateContestInfoDate(eventStartDate);
                    } else {
                        this.handleContestEndEvent(true);
                        this.countDownPrefix = "EVENT_ENDED";
                         
                    }
			}
        }
    },
    computed: {
        ...mapGetters([              
            'isEventEnded' 
           ]),
    },
    methods: {      
        ...mapActions([
            "handleContestEndEvent",
        ]),
        //countdown contest end date 
        updateContestInfoDate(endDate) {      
           
            var t = new Date();
            t.setSeconds(t.getSeconds() + 10);            
            let countDownDate = new Date(endDate).getTime();
            // Get today's date and time
          
            let now = new Date().getTime();

            // Find the distance between now and the count down date
            let distance = countDownDate - now;

            if (distance < 0) {
                // debugger
                // if (this.timeType === 1) {
                //     this.handleContestEndEvent(true)
                // }
                this.handleContestEndEvent(true)
                return;
            }
            // Time calculations for days, hours, minutes and seconds
            let days = Math.floor(distance / (1000 * 60 * 60 * 24));
            let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            let seconds = Math.floor((distance % (1000 * 60)) / 1000);

            // Display the result in the element with id="demo"
            this.eventTime.days = days<10 ? '0'+days: days;
            this.eventTime.hours =  hours<10 ? '0'+hours: hours;
            this.eventTime.min = minutes<10 ? '0'+minutes: minutes;
            this.eventTime.sec = seconds<10 ? '0'+seconds: seconds;
            // Update the count down every 1 second
            
            this.countDownInterval = setInterval(() => {              
                // Get today's date and time
                let now = new Date().getTime();
                
                // Find the distance between now and the count down date
                let distance = countDownDate - now;
 
                // Time calculations for days, hours, minutes and seconds
                let days = Math.floor(distance / (1000 * 60 * 60 * 24));
                let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                let seconds = Math.floor((distance % (1000 * 60)) / 1000);

                // Display the result in the element with id="demo"
                this.eventTime.days = days < 10 ? '0'+ days: days;
                this.eventTime.hours =  hours < 10 ? '0'+ hours: hours;
                this.eventTime.min = minutes < 10 ? '0'+ minutes: minutes;
                this.eventTime.sec = seconds < 10 ? '0'+ seconds: seconds;
                         
                // If the count down is finished, write some text
                if (distance/1000 < 2) {
                          
                    if (this.timeType === 3) {                                                     
                        //UPCOMING
                        this.$emit('emitCountdownEvent', true);
                    } else if (this.timeType === 2) {
                        //ONGOING
                        this.$emit('emitCountdownEvent', true);
                        this.countDownPrefix = "EVENT_ENDED";
                        this.handleContestEndEvent(true); 
                    }
                    
                    clearInterval(this.countDownInterval);
                }
            }, 1000);

        },
    },
}
</script>

<style scoped>
    .prediction-countdown__wrapper{
        margin-bottom: 1.5625rem;
    }
    .prediction-countdown__title{
        font-size: 1.125rem;
        
    }
    .prediction-countdown{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 1rem;
    }
    .prediction-countdown__time{
        display: flex;
        align-items: center;
        flex-direction: column;
        background-color: var(--color-theme);
        padding: 0.5rem;
        border-radius: 0.5rem;
    }
    .prediction-countdown__time-seperator{
        margin: 0 0.5rem;
    }
    .prediction-countdown__time-time{
        font-size: 1.375rem;
        font-weight: 700;
    }
    .prediction-countdown__time-day{
        font-size: 0.6875rem
    }
    .prediction-countdown__title-bold{
        font-weight: 700;
        font-size: 2.125rem;
    }
    @media (max-width: 768px){
        .prediction-countdown__wrapper{
            margin-bottom: 1.25rem;
        }
    }
</style>