<template>
  
    <div class="prediction-container">

        <div class="prediction-entry">
            <div v-if="isMobile" class="relative w-100 mobile--knockout-container" :class="{ 'mobile--knockout-container__live' : knockOutLive.isShow }">
                <div class="mobile--knockout__stripe-bg"></div>
                    <template v-if="info.isShowLiveData">
                        <p class="mobile--knockout__title">{{ `FIFA 2022 ${$t('LIVE_BRACKET')}` }}</p>
                    </template>
                    <template v-else>
                        <p class="mobile--knockout__title">{{ knockOutLive.isShow ? `FIFA 2022 ${$t('LIVE_BRACKET')}` : $t('SELECT_YOUR_TEAMS') }}</p>
                        <p v-if="!knockOutLive.isShow" class="mobile--knockout__desc">   
                            <span v-if="isEventEnded">{{ $t('EVENT_ENDED')}}</span>
                            <span v-else>{{ $t('EVENT_ENDS_ON') }} {{ eventEndDateFilter(contestInfo) }}</span>
                        </p>
                    </template>
                    <template v-if="info.isShowLiveData">
                    </template>
                    <template v-else>
                        <div v-if="!knockOutLive.isShow" class="mobile--knockout__point">
                            <div class="mobile--knockout__point-value">{{ myPredictionsData.myPredictionPoint }}</div>
                            <div class="mobile--knockout__point-text">{{ $t('PTS') }}</div>
                        </div>
                    </template>

                <swiper class="relative mobile--knockout-swiper" :class="{ 'mobile--knockout-swiper__live' : knockOutLive.isShow }" autoHeight=true :navigation="true" :pagination="{  clickable: true }" >
                    <swiper-slide class="mobile--knockout-swiper-slide-1">
                        <div class="mobile--knockout__match-header">
                            <div class="flex-1 mobile--knockout__match-header__title-1">{{ $t('ROUND_OF_16') }}</div>
                            <div class="flex-1 mobile--knockout__match-header__title-2">{{ $t('QUATER-FINALS') }}</div>
                        </div> 
                        <div class="relative display-flex">
                            <div class="mobile--knockout-card__wrapper-left" >
                                <template v-if="info.isShowMatchKnockOutsList">
                                    <template v-for="(o, index) in matchKnockOutsList" :key="index">
                                        <div v-if="o.sequenceId <= 56" class="knockout-card__wrapper"  :class="`mobile--knockout-card__${o.sequenceId}`" >
                                            <DropdownList 
                                                :contestInfo="contestInfo"
                                                :isDisplayOnly="info.isDisplayOnly"
                                                :isShowKnockOutLive="knockOutLive.isShow"
                                                :isShowLiveData="info.isShowLiveData"
                                                :default-text="o.home.displayTeamName" 
                                                :displayIcon="o.home.displayIcon" 
                                                :disabled="o.home.disabled" 
                                                :knockOutData="o"
                                                :status="'home'"
                                                :items="o.home.items"
                                                :hasError="o.home.hasError"                      
                                                @emitEvent="knockOutReceiveEvent($event)"
                                            ></DropdownList>                 
                                            <DropdownList 
                                                :contestInfo="contestInfo"
                                                :isDisplayOnly="info.isDisplayOnly"
                                                :isShowKnockOutLive="knockOutLive.isShow"
                                                :isShowLiveData="info.isShowLiveData"
                                                :default-text="o.away.displayTeamName" 
                                                :displayIcon="o.away.displayIcon" 
                                                :disabled="o.away.disabled" 
                                                :knockOutData="o"
                                                :status="'away'"
                                                :items="o.away.items" 
                                                :hasError="o.away.hasError" 
                                                @emitEvent="knockOutReceiveEvent($event)"
                                            ></DropdownList>
                                        </div>
                                    </template>
                                </template>
                                <template v-else>
                                    <template v-for="(o, index) in myPredictionsData.myPredictionsList" :key="index">
                                        <div v-if="o.sequenceId <= 56" class="knockout-card__wrapper"  :class="`mobile--knockout-card__${o.sequenceId}`" >
                                            <DropdownList 
                                                :contestInfo="contestInfo"
                                                :isDisplayOnly="true"
                                                :displayIcon="o.homeLogo"
                                                :knockOutData="o"
                                                :status="'home'"
                                            ></DropdownList>                 
                                            <DropdownList 
                                                :contestInfo="contestInfo"
                                                :isDisplayOnly="true"
                                                :displayIcon="o.awayLogo"
                                                :knockOutData="o"
                                                :status="'away'"
                                            ></DropdownList>
                                        </div>
                                    </template>
                                </template>
                            </div>
                            <div class="mobile--knockout-card__wrapper-right">
                                <template v-for="(o, index) in matchKnockOutsList" :key="index">
                                    <div v-if="o.sequenceId >= 57 && o.sequenceId <= 60" class="knockout-card__wrapper" style="left: 1rem"
                                        :style="mobileFitlerMatchRightStyling(-(56-o.sequenceId))"        
                                        :class="`mobile--knockout-card__${o.sequenceId}`" >
                                        <Line></Line>
                                        <Line v-if="o.sequenceId === 57 || o.sequenceId === 59" mode="medium"></Line>
                                        <DropdownList 
                                            :contestInfo="contestInfo"
                                            :isDisplayOnly="info.isDisplayOnly"
                                            :isShowKnockOutLive="knockOutLive.isShow"
                                            :isShowLiveData="info.isShowLiveData"
                                            :default-text="o.home.displayTeamName" 
                                            :displayIcon="o.home.displayIcon"
                                            :disabled="o.home.disabled" 
                                            :knockOutData="o"
                                            :status="'home'"
                                            :items="o.home.items" 
                                            :hasError="o.home.hasError"                      
                                            @emitEvent="knockOutReceiveEvent($event)"
                                        ></DropdownList>                 
                                        <DropdownList 
                                            :contestInfo="contestInfo"
                                            :isDisplayOnly="info.isDisplayOnly"
                                            :isShowKnockOutLive="knockOutLive.isShow"
                                            :isShowLiveData="info.isShowLiveData"
                                            :default-text="o.away.displayTeamName" 
                                            :displayIcon="o.away.displayIcon"
                                            :disabled="o.away.disabled" 
                                            :knockOutData="o"
                                            :status="'away'"
                                            :items="o.away.items" 
                                            :hasError="o.away.hasError" 
                                            @emitEvent="knockOutReceiveEvent($event)"
                                        ></DropdownList> 
                                    </div>
                                </template>
                                <template v-for="(o, index) in myPredictionsData.myPredictionsList" :key="index">
                                    <div v-if="o.sequenceId >= 57 && o.sequenceId <= 60" class="knockout-card__wrapper" style="left: 1rem"
                                        :style="mobileFitlerMatchRightStyling(-(56-o.sequenceId))"        
                                        :class="`mobile--knockout-card__${o.sequenceId}`" >
                                        <Line></Line>
                                        <Line v-if="o.sequenceId === 57 || o.sequenceId === 59" mode="medium"></Line>
                                        <DropdownList 
                                            :contestInfo="contestInfo"
                                            :isDisplayOnly="true"
                                            :displayIcon="o.homeLogo"
                                            :knockOutData="o"
                                            :status="'home'"
                                        ></DropdownList>                 
                                        <DropdownList 
                                            :contestInfo="contestInfo"
                                            :isDisplayOnly="true"
                                            :displayIcon="o.awayLogo"
                                            :knockOutData="o"
                                            :status="'away'"
                                        ></DropdownList>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </swiper-slide>   
                    <swiper-slide class="mobile--knockout-swiper-slide-2"> 
                          <div class="mobile--knockout__match-header">
                            <div class="flex-1 mobile--knockout__match-header__title-1">{{ $t('QUATER-FINALS') }}</div>
                            <div class="flex-1 mobile--knockout__match-header__title-2">{{ $t('SEMI-FINALS') }}</div>
                        </div> 
                        <div class="relative display-flex">
                            <div class="mobile--knockout-card__wrapper-left" >
                                <template v-for="(o, index) in matchKnockOutsList" :key="index">
                                    <div v-if="o.sequenceId >= 57 && o.sequenceId <= 60" class="knockout-card__wrapper" style="left: 2px"
                                        :style="mobileFitlerMatchLeftStyling(-(56-o.sequenceId))" 
                                        :class="`mobile--knockout-card__${o.sequenceId}`" >
                                        <DropdownList 
                                            :contestInfo="contestInfo"
                                            :isDisplayOnly="info.isDisplayOnly"
                                            :isShowKnockOutLive="knockOutLive.isShow"
                                            :isShowLiveData="info.isShowLiveData"
                                            :default-text="o.home.displayTeamName" 
                                            :displayIcon="o.home.displayIcon"
                                            :disabled="o.home.disabled" 
                                            :knockOutData="o"
                                            :status="'home'"
                                            :items="o.home.items"   
                                            :hasError="o.home.hasError"                    
                                            @emitEvent="knockOutReceiveEvent($event)"
                                        ></DropdownList>                 
                                        <DropdownList 
                                            :contestInfo="contestInfo"
                                            :isDisplayOnly="info.isDisplayOnly"
                                            :isShowKnockOutLive="knockOutLive.isShow"
                                            :isShowLiveData="info.isShowLiveData"
                                            :default-text="o.away.displayTeamName" 
                                            :displayIcon="o.away.displayIcon"
                                            :disabled="o.away.disabled" 
                                            :knockOutData="o"
                                            :status="'away'"
                                            :items="o.away.items" 
                                            :hasError="o.away.hasError" 
                                            @emitEvent="knockOutReceiveEvent($event)"
                                        ></DropdownList> 
                                    </div>
                                </template>
                                <template v-for="(o, index) in myPredictionsData.myPredictionsList" :key="index">
                                     <div v-if="o.sequenceId >= 57 && o.sequenceId <= 60" class="knockout-card__wrapper" style="left: 2px"
                                        :style="mobileFitlerMatchLeftStyling(-(56-o.sequenceId))" 
                                        :class="`mobile--knockout-card__${o.sequenceId}`" >                                     
                                        <DropdownList 
                                            :contestInfo="contestInfo"
                                            :isDisplayOnly="true"
                                            :displayIcon="o.homeLogo"
                                            :knockOutData="o"
                                            :status="'home'"
                                        ></DropdownList>                 
                                        <DropdownList 
                                            :contestInfo="contestInfo"
                                            :isDisplayOnly="true"
                                            :displayIcon="o.awayLogo"
                                            :knockOutData="o"
                                            :status="'away'"
                                        ></DropdownList>
                                    </div>
                                </template>
                            </div>
                            <div class="mobile--knockout-card__wrapper-right">
                                <template v-for="(o, index) in matchKnockOutsList" :key="index">
                                    <div v-if="o.sequenceId >= 61 && o.sequenceId <= 62" class="knockout-card__wrapper" style="left: 1rem"
                                        :style="mobileFitlerMatchRightStyling(-(60-o.sequenceId))"          
                                        :class="`mobile--knockout-card__${o.sequenceId}`" >
                                         <Line></Line>
                                         <Line v-if="o.sequenceId === 61" mode="medium"></Line>
                                        <DropdownList 
                                            :contestInfo="contestInfo"
                                            :isDisplayOnly="info.isDisplayOnly"
                                            :isShowKnockOutLive="knockOutLive.isShow"
                                            :isShowLiveData="info.isShowLiveData"
                                            :default-text="o.home.displayTeamName" 
                                            :displayIcon="o.home.displayIcon"
                                            :disabled="o.home.disabled" 
                                            :knockOutData="o"
                                            :status="'home'"
                                            :items="o.home.items"         
                                            :hasError="o.home.hasError"              
                                            @emitEvent="knockOutReceiveEvent($event)"
                                        ></DropdownList>                 
                                        <DropdownList 
                                            :contestInfo="contestInfo"
                                            :isDisplayOnly="info.isDisplayOnly"
                                            :isShowKnockOutLive="knockOutLive.isShow"
                                            :isShowLiveData="info.isShowLiveData"
                                            :default-text="o.away.displayTeamName" 
                                            :displayIcon="o.away.displayIcon"
                                            :disabled="o.away.disabled" 
                                            :knockOutData="o"
                                            :status="'away'"
                                            :items="o.away.items" 
                                            :hasError="o.away.hasError" 
                                            @emitEvent="knockOutReceiveEvent($event)"
                                        ></DropdownList> 
                                    </div>
                                </template>
                                <template v-for="(o, index) in myPredictionsData.myPredictionsList" :key="index">
                                    <div v-if="o.sequenceId >= 61 && o.sequenceId <= 62" class="knockout-card__wrapper" style="left: 1rem"
                                        :style="mobileFitlerMatchRightStyling(-(60-o.sequenceId))"          
                                        :class="`mobile--knockout-card__${o.sequenceId}`" >
                                         <Line></Line>
                                         <Line v-if="o.sequenceId === 61" mode="medium"></Line>
                                        <DropdownList 
                                            :contestInfo="contestInfo"
                                            :isDisplayOnly="true"
                                            :displayIcon="o.homeLogo"
                                            :knockOutData="o"
                                            :status="'home'"
                                        ></DropdownList>                 
                                        <DropdownList 
                                            :contestInfo="contestInfo"
                                            :isDisplayOnly="true"
                                            :displayIcon="o.awayLogo"
                                            :knockOutData="o"
                                            :status="'away'"
                                        ></DropdownList>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </swiper-slide>       
                    <swiper-slide class="mobile--knockout-swiper-slide-3"> 
                          <div class="mobile--knockout__match-header">
                            <div class="flex-1 mobile--knockout__match-header__title-1">{{ $t('SEMI-FINALS') }}</div>
                            <div class="flex-1 mobile--knockout__match-header__title-2">{{ $t('FINAL') }}</div>
                        </div> 
                        <div class="relative display-flex">
                            <div class="mobile--knockout-card__wrapper-left" >
                                <template v-for="(o, index) in matchKnockOutsList" :key="index">
                                    <div v-if="o.sequenceId >= 61 && o.sequenceId <= 62" class="knockout-card__wrapper" style="left: 2px"
                                        :style="mobileFitlerMatchLeftStyling(-(60-o.sequenceId))" 
                                        :class="`mobile--knockout-card__${o.sequenceId}`" >                                       
                                        <DropdownList
                                            :contestInfo="contestInfo" 
                                            :isDisplayOnly="info.isDisplayOnly"
                                            :isShowKnockOutLive="knockOutLive.isShow"
                                            :isShowLiveData="info.isShowLiveData"
                                            :default-text="o.home.displayTeamName" 
                                            :displayIcon="o.home.displayIcon"
                                            :disabled="o.home.disabled" 
                                            :knockOutData="o"
                                            :status="'home'"
                                            :items="o.home.items"     
                                            :hasError="o.home.hasError"                  
                                            @emitEvent="knockOutReceiveEvent($event)"
                                        ></DropdownList>                 
                                        <DropdownList
                                            :contestInfo="contestInfo" 
                                            :isDisplayOnly="info.isDisplayOnly"
                                            :isShowKnockOutLive="knockOutLive.isShow"
                                            :isShowLiveData="info.isShowLiveData"
                                            :default-text="o.away.displayTeamName" 
                                            :displayIcon="o.away.displayIcon"
                                            :disabled="o.away.disabled" 
                                            :knockOutData="o"
                                            :status="'away'"
                                            :items="o.away.items" 
                                            :hasError="o.away.hasError" 
                                            @emitEvent="knockOutReceiveEvent($event)"
                                        ></DropdownList> 
                                    </div>
                                </template>
                                     <template v-for="(o, index) in myPredictionsData.myPredictionsList" :key="index">
                                    <div v-if="o.sequenceId >= 61 && o.sequenceId <= 62" class="knockout-card__wrapper" style="left: 2px"
                                        :style="mobileFitlerMatchLeftStyling(-(60-o.sequenceId))" 
                                        :class="`mobile--knockout-card__${o.sequenceId}`" >                                       
                                        <DropdownList 
                                            :contestInfo="contestInfo"
                                            :isDisplayOnly="true"
                                            :displayIcon="o.homeLogo"
                                            :knockOutData="o"
                                            :status="'home'"
                                        ></DropdownList>                 
                                        <DropdownList 
                                            :contestInfo="contestInfo"
                                            :isDisplayOnly="true"
                                            :displayIcon="o.awayLogo"
                                            :knockOutData="o"
                                            :status="'away'"
                                        ></DropdownList>
                                    </div>
                                </template>
                            </div>
                            <div class="mobile--knockout-card__wrapper-right">
                                <template v-for="(o, index) in matchKnockOutsList" :key="index">
                                    <div v-if="o.sequenceId === 64" class="knockout-card__wrapper" style="left: 1rem"
                                        :style="mobileFitlerMatchRightStyling(-(63-o.sequenceId))"          
                                        :class="`mobile--knockout-card__${o.sequenceId}`" >
                                         <Line></Line>                               
                                        <DropdownList 
                                            :contestInfo="contestInfo"
                                            :isDisplayOnly="info.isDisplayOnly"
                                            :isShowKnockOutLive="knockOutLive.isShow"
                                            :isShowLiveData="info.isShowLiveData"
                                            :default-text="o.home.displayTeamName" 
                                            :displayIcon="o.home.displayIcon"
                                            :disabled="o.home.disabled" 
                                            :knockOutData="o"
                                            :status="'home'"
                                            :items="o.home.items"           
                                            :hasError="o.home.hasError"            
                                            @emitEvent="knockOutReceiveEvent($event)"
                                        ></DropdownList>                 
                                        <DropdownList 
                                            :contestInfo="contestInfo"
                                            :isDisplayOnly="info.isDisplayOnly"
                                            :isShowKnockOutLive="knockOutLive.isShow"
                                            :isShowLiveData="info.isShowLiveData"
                                            :default-text="o.away.displayTeamName" 
                                            :displayIcon="o.away.displayIcon"
                                            :disabled="o.away.disabled" 
                                            :knockOutData="o"
                                            :status="'away'"
                                            :items="o.away.items"
                                            :hasError="o.away.hasError"  
                                            @emitEvent="knockOutReceiveEvent($event)"
                                        ></DropdownList> 
                                    </div>
                                </template>
                                 <template v-for="(o, index) in myPredictionsData.myPredictionsList" :key="index">
                                    <div v-if="o.sequenceId === 64" class="knockout-card__wrapper"  
                                        :style="mobileFitlerMatchRightStyling(-(63-o.sequenceId))"          
                                        :class="`mobile--knockout-card__${o.sequenceId}`" >
                                        <Line></Line>                                
                                        <DropdownList 
                                            :contestInfo="contestInfo"
                                            :isDisplayOnly="true"
                                            :displayIcon="o.homeLogo"
                                            :knockOutData="o"
                                            :status="'home'"
                                        ></DropdownList>                 
                                        <DropdownList 
                                            :contestInfo="contestInfo"
                                            :isDisplayOnly="true"
                                            :displayIcon="o.awayLogo"
                                            :knockOutData="o"
                                            :status="'away'"
                                        ></DropdownList>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </swiper-slide>      
                     
                </swiper>
                <div v-if="knockOutLive.isShow" class="relative mobile--knockout__champion" >
                    <div class="mobile--knockout__divider"></div>
                    <div class="mobile--knockout__champion-wrapper">
                        <img class="mobile--knockout__champion-img" src="../../../../static/images/worldcup2022/worldcup_champion.png">   
                        <div>{{ $t('CHAMPION') }}</div>  
                    </div>
                    <div class="knockout-card mobile__third-place__card">
                        <div class="drop-down-list__field no-hover">
                            <img v-if="finalBracket.hasLogo" class="drop-down-list__field__icon" :src="`${imageUrlPrefix}/${finalBracket.teamLogo}`"> 
                            <img v-else class="drop-down-list__field__icon" src="../../../../static/images/worldcup2022/bracket_team_icon_default.png">            
                            <div class="drop-down-list__field__label">
                                {{ finalBracket.teamName }}
                            </div>   
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="prediction-entry__body">
                <div v-if="knockOutLive.isShowIcon" class="knockout-switch__wrapper"  @click="handleKnockOutSwitchViewDesktop">
                    <div v-if="info.knockOutSwitchIcon ==='icon_bracket_back'">
                        <img class="w-100 h-100"  :src="require(`../../../../static/images/icons/${info.knockOutSwitchIcon}.png`)">
                    </div>
                    <div v-else @mouseover="info.isHoverBracketIcon = true" @mouseleave="info.isHoverBracketIcon = false">
                        <div v-if="info.isHoverBracketIcon" class="relative">
                            <img class="w-100 h-100" :src="require(`../../../../static/images/icons/icon_bracket_hover.png`)">
                            <div class="knockout-switch__bracket-hover"> 
                                <img class="knockout-switch__bracket-triangle" src="../../../../static/images/triangle_top.png">
                                <div class="knockout-switch__bracket-rect">{{ $t('LIVE_BRACKET') }}</div>
                            </div>
                        </div>
                        <div v-else class="relative">
                            <img class="w-100 h-100"  :src="require(`../../../../static/images/icons/icon_bracket.png`)">                         
                        </div>
                    </div>
               </div>
                <div class="prediction-entry__body__stripes-graphics"></div>
                <div v-if="knockOutLive.isShow" class="prediction-entry__body__champion-wrapper">
                    <img src="../../../../static/images/worldcup2022/trophy-fireworks.png">
                    <div class="knockout-card">
                        <div class="drop-down-list__field no-hover">
                            <img v-if="finalBracket.hasLogo" class="drop-down-list__field__icon" :src="`${imageUrlPrefix}/${finalBracket.teamLogo}`"> 
                            <img v-else class="drop-down-list__field__icon" src="../../../../static/images/worldcup2022/bracket_team_icon_default.png">            
                            <div class="drop-down-list__field__label">
                                {{ finalBracket.teamName }}
                            </div>   
                        </div>
                    </div>
                </div>
                <div v-else class="prediction-entry__body__world-cup-logo"></div>

                
                <div class="prediction-entry__body__select-your-teams-text" :class="{ 'prediction-entry__body__worldcup-teams-text' : knockOutLive.isShow}">
                    <template v-if="info.isShowLiveData">
                        <p>{{ $t('FIFA_WORLD_CUP') }} 2022</p>
                        <p>{{ $t('LIVE_BRACKET') }}</p>
                    </template>
                    <template v-else>
                        <p>{{ knockOutLive.isShow ? `${$t('FIFA_WORLD_CUP')} 2022` : $t('SELECT') }}</p>
                        <p>{{ knockOutLive.isShow ? $t('LIVE_BRACKET') : $t('YOUR_TEAMS') }}</p>
                    </template>                
                </div>
                <template v-if="info.isShowLiveData">
                </template>
                <template v-else>
                    <div v-if="!knockOutLive.isShow" class="prediction-entry__body__event-ends-on-date-text">
                        <span v-if="isEventEnded">{{ $t('EVENT_ENDED')}}</span>
                        <span v-else> {{ $t('EVENT_ENDS_ON') }} {{ eventEndDateFilter(contestInfo) }}</span>
                    </div>
                </template>

                <template v-if="info.isShowLiveData">
                </template>
                <template v-else>
                    <div v-if="!knockOutLive.isShow" class="prediction-entry__body__points-value-text">{{ myPredictionsData.myPredictionPoint }}</div>
                    <div v-if="!knockOutLive.isShow" class="prediction-entry__body__points-unit-text">{{ $t('PTS') }}</div>
                </template>

                <div class="prediction-entry__body__grand-final-highlight-frame"></div>
                <div class="prediction-entry__body__grand-final-text">{{ $t('GRAND_FINAL')}}</div>

                <div class="prediction-entry__body__lines">
                    <div class="prediction-entry__body__lines__group-57"></div>
                    <div class="prediction-entry__body__lines__group-58"></div>
                    <div class="prediction-entry__body__lines__group-59"></div>
                    <div class="prediction-entry__body__lines__group-60"></div>
                    <div class="prediction-entry__body__lines__group-61"></div>
                    <div class="prediction-entry__body__lines__group-62"></div>
                </div>
                <div class="prediction-entry__body__inputs">              
                    <template v-for="(o, index) in matchKnockOutsList" :key="index">
                         <DropdownList 
                            :contestInfo="contestInfo"
                            :isDisplayOnly="info.isDisplayOnly"
                            :isShowLiveData="info.isShowLiveData"
                            :isShowKnockOutLive="knockOutLive.isShow"
                            :default-text="o.home.displayTeamName" 
                            :displayIcon="o.home.displayIcon" 
                            :disabled="o.home.disabled" 
                            :knockOutData="o"
                            :status="'home'"
                            :items="o.home.items"
                            :hasError="o.home.hasError" 
                            :class="`knockout-card knockout-card__${o.sequenceId}-home`"
                            @emitEvent="knockOutReceiveEvent($event)"
                        ></DropdownList>   
                               
                        <DropdownList
                            :contestInfo="contestInfo" 
                            :isDisplayOnly="info.isDisplayOnly"
                            :isShowLiveData="info.isShowLiveData"
                            :isShowKnockOutLive="knockOutLive.isShow"
                            :default-text="o.away.displayTeamName"
                            :displayIcon="o.away.displayIcon" 
                            :disabled="o.away.disabled" 
                            :knockOutData="o"
                            :status="'away'"
                            :items="o.away.items" 
                            :hasError="o.away.hasError" 
                            :class="`knockout-card knockout-card__${o.sequenceId}-away`"
                            @emitEvent="knockOutReceiveEvent($event)"
                        >
                       </DropdownList>
                    </template>               
                    <template v-for="(o, index) in myPredictionsData.myPredictionsList" :key="index"> 
                        <DropdownList
                            :contestInfo="contestInfo"
                            :isDisplayOnly="true"
                            :knockOutData="o"
                            :displayIcon="o.homeLogo"
                            :myPredictionData="o"
                            :status="'home'"
                            :class="`knockout-card knockout-card__${o.sequenceId}-home`"
                            :default-text="o.homeName"
                        >
                        </DropdownList>
                        <DropdownList
                            :contestInfo="contestInfo"
                            :isDisplayOnly="true"
                            :displayIcon="o.awayLogo" 
                            :knockOutData="o"
                            :isMyPrediction="true"
                            :status="'away'"
                            :class="`knockout-card knockout-card__${o.sequenceId}-away`"
                            :default-text="o.awayName"
                        >
                        </DropdownList>
                    </template>
                </div>
             
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { Swiper, SwiperSlide } from 'swiper/vue';
import SwiperCore, { Pagination, Navigation, Autoplay} from 'swiper/core';
SwiperCore.use([ Pagination, Navigation, Autoplay ]);
import config from '@/js/config.js';

import DropdownList from '@/components/event/worldcup/Dropdown.vue';
import Line from '@/components/event/worldcup/Line.vue';
import moment from 'moment'

const defaultTeamName = 'TBD';
const defaultTeamScore = "-";
const defaultTeamLogo = "../../../../static/images/worldcup2022/bracket_team_icon_default.png";

export default {
    components: {
        Swiper,
        SwiperSlide,
        DropdownList,
        Line  
    },

    props: {
        contestId: {
            default: 4
        },
        contestInfo: {
            type: Object,
            default: {}
        },
        isReload: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            imageUrlPrefix: config.s3ImgUrl,
            selectionsAllList: null,
            isInitialLoad: false,
            info: {
                knockOutRange: [49,56],
                isDisplayOnly: false,
                isShowLiveData: false,
                isPaticipated: false,
                isHoverBracketIcon: false,
                isShowMatchKnockOutsList: true,
                defaultDropdownText: "SELECT_TEAM",

                knockOutSwitchIcon: 'icon_bracket',
                
            },

            // knockOutLive: {
            //     isShowIcon: false,
            //     isShow: false
            // },
            
            myPredictionsData: {
                myPredictionPoint: '-',
                myPredictionsList: []
            },

            finalBracket: {}
        };
    },
    watch:{
        isLogin: {
            deep: true,
            handler(newVal, preVal) {
                //logout
                if (newVal === false) {
                   this.init();
                }
             
                if (!this.isInitialLoad) {
                    this.initKnockOutLive();
                }
            }
        },
        isEventEnded: {
            deep: true,
            handler(newVal,oldVal) {
                if (newVal) {
                    //this.info.isDisplayOnly = true;
                }
            }
        },
        knockOutLive: {
            deep: true,
            handler(newVal,oldVal) {
                if (!this.isInitialLoad) {
                    this.handleKnockOutSwitchView();
                }
            }
        }
    },
    computed: {
        ...mapGetters([              
                'isMobile',
                'currentLocale',
                'currentLanguageObj',
                'isLogin',
                'matchKnockOutsList',
                'isJoinEvent',
                'isEventEnded',
                'knockOutLive'
           ]),
    },
    mounted() {
        this.isInitialLoad = true;
    },
    methods: {
        ...mapActions([
            "getKnockout",
            "getSelections",
            "handleKnockOutList",
            "getMyPrediction",
            "handleContestJoinEvent",
            "handleKnockOutEvent"
        ]),
        
        init() {
            //reset knockout show icon event
            this.handleKnockOutEvent({isShowIcon: false, isShow: false});

            this.isInitialLoad = false;

            if (this.isLogin) {
                this.getMyPredictionData();
                this.isPaticipated = false;
            } else {
                this.getSelectionsData();
            } 

            if (this.isEventEnded && this.contestInfo.timeType === 1) {
                this.info.isDisplayOnly = true;
            } 
           
            //this.initKnockOutLive();
        },

        initKnockOutData(result) {
            result.forEach(x => {
                x.home = {
                    displayIcon: null,
                    displayTeamName: this.knockoutTextFilter(x, 'home'),
                    disabled: this.knockoutDisableFilter(x),
                    selectedTeam: null,
                    items: this.knockoutDropdownItemListFitler(x, 'home'),
                    hasError: false
                };
                x.away = {
                    displayIcon: null,
                    displayTeamName:  this.knockoutTextFilter(x, 'away'),
                    disabled: this.knockoutDisableFilter(x),
                    selectedTeam: null,
                    items: this.knockoutDropdownItemListFitler(x, 'away'),
                    hasError: false
                }
            });
            this.handleKnockOutList(result)
        },
        
        knockOutReceiveEvent(receiveData) { 
            //only allow child knockout dropdown enable when parent home and away team have been selected 
            if (receiveData.home.selectedTeam && receiveData.away.selectedTeam) {    
                this.matchKnockOutsList.forEach(item => { 
                    if (item.parentHomeSequenceId === receiveData.sequenceId) {
                        this.knockoutChildFillDropdwon(item, receiveData, 'home')
                    } else if (item.parentAwaySequenceId === receiveData.sequenceId){
                        this.knockoutChildFillDropdwon(item, receiveData, 'away')
                    }
                });

                this.$emit("emitKnockoutEvent", this.matchKnockOutsList)
            }
        },

        //start get data section
        async getKnockOutData() {
            let params = {
                contestId: this.contestId
            } 
            const result = await this.getKnockout(params);

            this.info.isShowMatchKnockOutsList = true;
            this.initKnockOutData(result.result.matchKnockouts);

            this.finalBracket = this.getFinalBracketData(result.result.matchKnockouts.filter(x => x.sequenceId === 64)[0]);

            if (!this.knockOutLive.isShow) {
                this.handleContestJoinEvent(false);
            }
            
            this.initKnockOutLive();
            this.resetMyPredictionData();
        },

        async getSelectionsData() {
            let params = {
                contestId: this.contestId
            }
            const result = await this.getSelections(params);

            this.selectionsAllList = result.result.selections;
            this.getKnockOutData();
        },

        async getMyPredictionData() { 
            let params = {
                contestId: this.contestId,
            }

            const result = await this.getMyPrediction(params);
            if (result.result !== null) {
                this.isPaticipated = !result.result.allowSubmit;

                if (result.result.allowSubmit) {
                    this.info.isShowMatchKnockOutsList = true;
                    this.getSelectionsData();
                } else {
                    this.info.isShowMatchKnockOutsList = false;
                    this.handleContestJoinEvent(true);

                    this.myPredictionsData = {
                        myPredictionPoint: result.result.myPredictions[0].myPredictionPoint,
                        myPredictionsList: result.result.myPredictions[0].myPrediction
                    }
                }
                this.initKnockOutLive();
                //reset knockoutlist data
                this.handleKnockOutList([])
            }
        },
        //end get data

        //start filter data section
        eventEndDateFilter(o) {
            return moment(o.endDate).utcOffset(0).format("dddd D MMMM, HH:mm") + "GMT"
        },

        knockoutDropdownItemListFitler(o, status) {
            if (this.isEventEnded || this.knockOutLive.isShow) {
                 return []
            } else {
                if (o.sequenceId >= this.info.knockOutRange[0] && o.sequenceId <= this.info.knockOutRange[1]) {
                    let result = o[`${status}Grouped`];
                    return this.selectionsAllList[result];
                } else {
                    return []
                }
            }       
        },
        
        knockoutDisableFilter(o) {
            if(this.contestInfo.timeType === 2 && this.isEventEnded) {
                return true
            } else {
                if (o.sequenceId >= this.info.knockOutRange[0] && o.sequenceId <= this.info.knockOutRange[1]) {
                    return false
                } else {
                    return true
                }
            }

        },

        knockoutTextFilter(o, status) {
            if (this.info.isDisplayOnly) {
                return status === 'home' ? o.homeName : o.awayName
            } else {
                const defaultDropdownText = this.$i18n.t(this.info.defaultDropdownText);

                if (o.sequenceId >= this.info.knockOutRange[0] && o.sequenceId <= this.info.knockOutRange[1]) {
                    return status === 'home' ? `${defaultDropdownText} (1${o.homeGrouped})` : `${defaultDropdownText} (2${o.awayGrouped})`
                } else {
                    return defaultDropdownText;
                }
            }      
        },

        mobileFitlerMatchLeftStyling(index) {
            let result;
            const firstPosition = 1;
            const bigGap = 48;
            const smallGap = 24;
            const cardHeight = 71.59;
            let diffIndex = index - 1
            //odd
            if (index % 2) {
                result = firstPosition + (cardHeight * diffIndex) + ((bigGap + smallGap) * Math.floor(index / 2))
            } else {
                result = firstPosition + (cardHeight * diffIndex) + (bigGap * Math.floor(index / 2) + (smallGap * (Math.floor(index / 2)- 1)) )
            }
            return `top: ${result}px`
        },

        mobileFitlerMatchRightStyling(index) {
            const gap = 215.59;
            const firstPosition = 57;
            const firstSequenceId = 57;
           
            let result;

            if (index === 1) {
                result = firstPosition
            } else {
                result = firstPosition + (gap * (index - 1)) ;
            }

            return `top: ${result}px`
        },      
        //end filter data section
        
        knockoutChildFillDropdwon(item, receiveData, status) {
            const defaultDropdownText = this.$i18n.t(this.info.defaultDropdownText);
            //reset child dropdown
            item[status].items = [];            
            item[status].disabled = false;
            item[status].displayIcon = null;
            item[status].selectedTeam = null;
            item[status].displayTeamName = defaultDropdownText;
               
            //fill in new knockout list item to dropdwon
            item[status].items.push(receiveData.home.selectedTeam);
            item[status].items.push(receiveData.away.selectedTeam);
        },

        resetMyPredictionData() {
            this.myPredictionsData = {
                myPredictionPoint: '-',
                myPredictionsList: []
            }
        },

        //knockout live section
          initKnockOutLive() {           
             //Except upcoming(3)
             //Live bracket only appears in Ongoing(2) and Past Event(1)     
    
            if (this.contestInfo.timeType === 3) {
                //upcoming (3)
                this.handleKnockOutEvent({isShowIcon: false})  
            } else if (this.contestInfo.timeType === 2) {      
                //ongoing (2)
                if (this.isLogin) {                   
                    this.handleKnockOutEvent({isShowIcon: true})
                } else {
                    this.handleKnockOutEvent({isShowIcon: false})
                    //this.info.isShowLiveData = true;
                }   
            } else {
                //past event (1)
                if (this.isLogin && this.isPaticipated) {
                    this.handleKnockOutEvent({isShowIcon: true})
                } else {
                    this.handleKnockOutEvent({isShowIcon: false})
                    this.info.isShowLiveData = true;
                }
            }
        },

        handleKnockOutSwitchViewDesktop() {
            this.handleKnockOutEvent({isShow: !this.knockOutLive.isShow})          
        },

        handleKnockOutSwitchView() {       
            //this.knockOutLive.isShow = !this.knockOutLive.isShow;
            if (this.contestId) {
                if (this.knockOutLive.isShow) {
                    this.getSelectionsData();
                    this.info.knockOutSwitchIcon = 'icon_bracket_back'
                } else {
                    if (this.isLogin) {
                        this.getMyPredictionData();
                    } else {
                        this.getSelectionsData();
                    }
                    this.info.knockOutSwitchIcon = 'icon_bracket'
                }
            }
        },

         getFinalBracketData(entry) {
            let obj = {};
           
            if (entry.championId) {
                if (entry.championId === entry.homeId) {
                    obj.teamName = entry.homeName;
                    obj.teamLogo = entry.homeLogo;
                    obj.teamScore = entry.homeScore;
                    obj.hasLogo = true;
                } else if (entry.championId === entry.awayId) {
                    obj.teamName = entry.awayName;
                    obj.teamLogo = entry.awayLogo;
                    obj.teamScore = entry.awayScore;
                    obj.hasLogo = true;
                }
            } else {
                obj.teamName = defaultTeamName;
                obj.teamLogo = defaultTeamLogo;
                obj.teamScore = defaultTeamScore;
                obj.hasLogo = false;
            }
            return obj
        },
    },
}
</script>

<style scoped>
.prediction-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    width: 100%;
    height: auto;
    /* background: url(../../../../static/images/worldcup2022/background.jpg);

    background-size: cover; */
}

.prediction-entry {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    width: 100%;
    height: auto;
    margin-bottom: 24px;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.prediction-entry__header {
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 8px;

    width: 100%;
    height: 88px;

    flex: none;
    order: 0;
    flex-grow: 0;

    filter: drop-shadow(0px 2px 4px #000000);

    user-select: none;

    pointer-events: none;
}

.prediction-entry__header__title-text {
    /* Header */
    width: 179px;
    height: 26px;


    font-family: 'OpenSans';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 26px;
    /* identical to box height, or 118% */


    /* White */

    color: #FFFFFF;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}

.prediction-entry__header__description-text {
    /* Choose your final teams for UEFA Champion League */


    width: auto;
    height: 16px;

    /* Body 2 */

    font-family: 'OpenSans';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 16px;
    /* identical to box height, or 145% */

    text-align: center;

    /* White */

    color: #FFFFFF;


    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;

}

.prediction-entry__body {
    /* event */
    position: relative;

    width: 1200px;
    height: 652px;

    background: rgba(0, 0, 0, 0.7);
    border-radius: 32px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}

.prediction-entry__body__stripes-graphics {
    position: absolute;
    width: 394.5px;
    height: 705.5px;
    left: 375px;
    top: 0;
    background: url(../../../../static/images/worldcup2022/new/stripes_graphics.png);
    background-repeat: no-repeat;
}

.prediction-entry__body__world-cup-logo {
    position: absolute;
    width: 125.36px;
    height: 150px;
    left: 537px;
    top: 436px;

    background: url(../../../../static/images/worldcup2022/new/fifa_world_cup_2022.png);
    background-repeat: no-repeat;
}
.prediction-entry__body__champion-wrapper{
    position: absolute;
    top: 26.5rem;
    left: 25.5rem;
}

.prediction-entry__body__select-your-teams-text {
    position: absolute;
    width: 370px;
    height: 120px;
    left: 421px;
    top: 24px;
    font-size: 57px;
    text-align: center;
    line-height: 1;
    /* background: url(../../../../static/images/worldcup2022/new/select_your_teams.png);
    background-repeat: no-repeat; */
}
 .prediction-entry__body__worldcup-teams-text{
    top: 111px;
    font-size: 36px;
 }
.prediction-entry__body__select-your-teams-text p{
    color: transparent;
    font-style: italic;
    font-weight: 700;
    -webkit-text-stroke: 2px white;
    text-shadow: 2px 2px 6px #ffffff40;
}
.prediction-entry__body__event-ends-on-date-text {
    position: absolute;
    width: 375px;
    height: 20px;
    left: 431px;
    top: 152px;
    font-family: 'OpenSans';
    font-style: italic;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none;
    text-align: center;
    padding: 0 1rem;
    box-sizing: border-box;
}

.prediction-entry__body__points-unit-text {
    /* pts */


    position: absolute;
    width: 21px;
    height: 21px;
    left: 617px;
    top: 223px;

    /* Body 1 */

    font-family: 'OpenSans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height, or 150% */

    text-align: center;

    /* White */
    user-select: none;
    pointer-events: none;
}

.prediction-entry__body__points-value-text {
    position: absolute;
    width: 59px;
    height: 60px;
    left: 557px;
    top: 188px;
    font-family: 'OpenSans';
    font-style: normal;
    font-weight: 700;
    font-size: 53px;
    line-height: 60px;
    text-align: center;
    color: #FFFFFF;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    text-align: right;
    pointer-events: none;
}

.prediction-entry__body__grand-final-highlight-frame {
    position: absolute;
    width: 184px;
    height: 110px;
    left: 508px;
    top: 271px;
    background: url(../../../../static/images/worldcup2022/new/grand_final_highlight_frame.png);
    background-repeat: no-repeat;
}

.prediction-entry__body__grand-final-text {
    position: absolute;
    width: 268px;
    height: 26px;
    left: 475px;
    top: 370px;

    font-family: 'OpenSans';
    font-style: italic;
    font-weight: 700;
    font-size: 28px;
    line-height: 26px;
    /* identical to box height, or 93% */

    text-align: center;

    /* White */

    color: #FFFFFF;

    text-shadow: 0px 4px 16px rgba(196, 202, 252, 0.5), 0px 0px 4px rgba(255, 255, 255, 0.2);
    user-select: none;
    pointer-events: none;
}

.prediction-entry__body__lines {
    position: relative;
}

.prediction-entry__body__lines__group-57 {
    position: absolute;
    width: 32px;
    height: 100px;
    left: 192px;
    top: 108px;
    background: url(../../../../static/images/worldcup2022/new/line_type_1.png);
    background-repeat: no-repeat;
}

.prediction-entry__body__lines__group-58 {
    position: absolute;
    width: 32px;
    height: 100px;
    left: 192px;
    top: 444px;
    background: url(../../../../static/images/worldcup2022/new/line_type_1.png);
    background-repeat: no-repeat;
}

.prediction-entry__body__lines__group-59 {
    position: absolute;
    width: 32px;
    height: 100px;
    right: 192px;
    top: 108px;
    background: url(../../../../static/images/worldcup2022/new/line_type_2.png);
    background-repeat: no-repeat;
}

.prediction-entry__body__lines__group-60 {
    position: absolute;
    width: 32px;
    height: 100px;
    right: 192px;
    top: 444px;
    background: url(../../../../static/images/worldcup2022/new/line_type_2.png);
    background-repeat: no-repeat;
}

.prediction-entry__body__lines__group-61 {
    /* Group 4 */


    position: absolute;
    width: 32px;
    height: 268px;
    left: 304px;
    top: 192px;
    background: url(../../../../static/images/worldcup2022/new/line_type_3.png);
    background-repeat: no-repeat;
}

.prediction-entry__body__lines__group-62 {
    position: absolute;
    width: 32px;
    height: 268px;
    right: 304px;
    top: 192px;
    background: url(../../../../static/images/worldcup2022/new/line_type_4.png);
    background-repeat: no-repeat;
}

.prediction-entry__body__inputs {
    position: relative;
}
.drop-down-list__field {
    position: relative;
    height: 32px;
    width: 100%;
    background: #35454D;
    border-radius: 4px;
    box-sizing: border-box;
    color: #CFD9DE;
    cursor: pointer;
    transition: box-shadow linear 0.1s;
    display: flex;
    align-items: center;
    padding: 0 0.5625rem;
}
.drop-down-list__field.no-hover{
    cursor: unset;
}
.drop-down-list__field__label {
    font-size: 11px;
}
.drop-down-list__field__icon {
    margin-right: 0.5rem;
    width: 0.8125rem;
    -o-object-fit: contain;
    object-fit: contain;
}
.knockout-card {
    position: absolute;
    width: 160px;
    height: 32px;
}
.prediction-entry__body__champion-wrapper .knockout-card{
    top: 3.5rem;
    left: 7rem;
}
.knockout-switch__wrapper{
    position: absolute;
    right: 1.5rem;
    top: 1rem;
    width: 2.5rem;
    height: 2.5rem;
    cursor: pointer;
}
.knockout-switch__bracket-hover{
    position: absolute;
    top: 2.3rem;
    right: -1.3rem;
    width: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.knockout-switch__bracket-triangle{
    width: 10px;;
    height: 16px;;
}
.knockout-switch__bracket-rect{
    background-color: white;
    padding: 0.25rem 0.2rem;
    color: #000000;
    font-size: 0.5875rem;
    border-radius: 0.25rem;
}
.knockout-card__49-home {
    top: 40px;
    left: 112px;
}

.knockout-card__49-away {
    top: 76px;
    left: 112px;
}

.knockout-card__50-home {
    top: 208px;
    left: 112px;
}

.knockout-card__50-away {
    top: 244px;
    left: 112px;
}

.knockout-card__51-home {
    top: 40px;
    right: 112px;
}

.knockout-card__51-away {
    top: 76px;
    right: 112px;
}

.knockout-card__52-home {
    top: 208px;
    right: 112px;
}

.knockout-card__52-away {
    top: 244px;
    right: 112px;
}

.knockout-card__53-home {
    top: 376px;
    left: 112px;
}

.knockout-card__53-away {
    top: 412px;
    left: 112px;
}

.knockout-card__54-home {
    top: 544px;
    left: 112px;
}

.knockout-card__54-away {
    top: 580px;
    left: 112px;
}

.knockout-card__55-home {
    top: 376px;
    right: 112px;
}

.knockout-card__55-away {
    top: 412px;
    right: 112px;
}

.knockout-card__56-home {
    top: 544px;
    right: 112px;
}

.knockout-card__56-away {
    top: 580px;
    right: 112px;
}

.knockout-card__57-home {
    top: 124px;
    left: 224px;
}

.knockout-card__57-away {
    top: 160px;
    left: 224px;
}

.knockout-card__58-home {
    top: 460px;
    left: 224px;
}

.knockout-card__58-away {
    top: 496px;
    left: 224px;
}

.knockout-card__59-home {
    top: 124px;
    right: 224px;
}

.knockout-card__59-away {
    top: 160px;
    right: 224px;
}

.knockout-card__60-home {
    top: 460px;
    right: 224px;
}

.knockout-card__60-away {
    top: 496px;
    right: 224px;
}

.knockout-card__61-home {
    top: 292px;
    left: 336px;
}

.knockout-card__61-away {
    top: 328px;
    left: 336px;
}

.knockout-card__62-home {
    top: 292px;
    right: 336px;
}

.knockout-card__62-away {
    top: 328px;
    right: 336px;
}

.knockout-card__63-grand-final {
    top: 328px;
    left: 520px;
}

.knockout-card__64-home {
    top: 292px;
    left: 520px;
}
.knockout-card__64-away {
    top: 328px;
    left: 520px;
}
.knockout-card__wrapper{
    position: absolute;
    width: 140px;
}
.mobile--knockout__divider{
    width: 100%;
    height: 2px;
    background-color: #4B616C;
    margin-bottom: 1rem;
    margin-top: 0.5rem;
}
.mobile--knockout__champion{
    margin-bottom: 1.5rem;
}
.mobile--knockout__champion-img{
    height: 2.125rem;
}
.mobile--knockout__champion-wrapper{
    display: flex;
    align-items: center;
    width: 140px;
    justify-content: center;
    margin-bottom: 0.25rem;
}
.mobile--knockout-container{
    background-image: linear-gradient(to bottom, #000000b3, #212b30ad);
    border-radius: 0.5rem;
    padding: 1rem;
    padding-bottom: 3rem;
    box-sizing: border-box;
}

.mobile--knockout__stripe-bg{
    width: 100%;
    height: 609px;
    background: url(../../../../static/images/worldcup2022/new/mobile_stripes_graphics.png);
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    top: 0;
    left: 0;
}
.mobile--knockout__title{
    color: transparent;
    font-style: italic;
    font-weight: 900;
    -webkit-text-stroke: 0.8px white;
    text-shadow: 2px 2px 6px #ffffff40;
    text-align: center;
    font-size: 1.8rem;
    margin-bottom: .25rem;
    position: relative;
}
.mobile--knockout__desc{
    font-size: 0.6875rem;
    font-style: italic;
    text-align: center;
    position: relative;
}
.mobile--knockout__point{
    display: flex;
    justify-content: center;
    position: relative;
    align-items: center;
    margin-top: 2.8rem;
}
.mobile--knockout__point-value{
    font-size: 3.125rem;
    font-weight: 700;
}
.mobile--knockout__point-text{
    font-size: 0.875rem;
    margin-top: 2rem;
}
.mobile--knockout-swiper{
    padding-top: 7rem;
    top: -6.5rem;
}

.mobile--knockout-swiper-slide-1{
    height: 900px;
}
.mobile--knockout-swiper-slide-2{
    height: 460px;
}
.mobile--knockout-swiper-slide-3{
    height: 255px;
}
.mobile--knockout-btn{
    position: absolute;
    top: 7rem;
}
.mobile--knockout-btn.next{
    right: 0;
}
.mobile--knockout-btn.prev{
    left: 0;
}

.mobile--knockout__match-header{
    display: flex;
    margin-bottom: 1rem;
    justify-content: space-between;
}
.mobile--knockout__match-header__title-1{
    padding-left: 2rem;
    width: 34%;
    flex: none;
}
.mobile--knockout__match-header__title-2{
    padding-right: 1.4rem;
    text-align: center;
    width: 34%;
    flex: none;
}

.mobile--knockout-card__wrapper-left{
    position: relative;
    flex: 1;
}
.mobile--knockout-card__wrapper-right{
    position: relative;
    flex: 1;
}
.mobile--knockout-card__49{
    top: 0px;
}

.mobile--knockout-card__50{
    top: 120px;    
}

.mobile--knockout-card__51{
    top: 430.74px;
}

.mobile--knockout-card__52{
     top: 550.33px;
}
.mobile--knockout-card__53{
    top: 215.59px;
    
}
.mobile--knockout-card__54{
    top: 335.18px;
}
.mobile--knockout-card__55{
    top: 645.92px;
}
.mobile--knockout-card__56{
    top: 765.51px;
}
.mobile--knockout-card__57{
    top: 50px;
}
.mobile--knockout-card__58{
    top: 200px;
}
.mobile--knockout-card__59{
    top: 350px;
}
.mobile--knockout-card__60{
    top: 450px;
}
.mobile--knockout-card__61{
    top: 50px;
}
.mobile--knockout-card__62{
    top: 150px;
}
.mobile--knockout-card__64{
   top: 250px
}

/* knockout live */
.mobile--knockout-swiper__live{
    top: 0;
    padding-top: 2rem;
}
.mobile--knockout-container__live{
    padding-bottom: 0;
}
.mobile__third-place__card{
    position: relative;
    width: 140px;
}
</style>