<template>
    <!-- Join event-->
    <div class="event-team__wrapper scroll-from-top" :class="{'bg-transparent': isBgTransparent}" >
        <!-- <h3 class="mb-1rem tc">
            <span>{{ 
                isJoinedEvent 
                ? $t('YOU_HAVE_PARTICIPATE_THIS_EVENT') 
                : $t('PARTICIPATE_THIS_EVENT')
                }}
            </span>              
        </h3> -->
        <div class="display-flex-center flex-col" :class="{'pe-none': isJoinedEvent}">
            <SocialMediaAndTermCondition  @changeTickEvent2="changeTickEvent2($event)" :isTick="isJoinedEvent" :disabled="!info.isAllDropdownFilled || isEventEnded"></SocialMediaAndTermCondition>
            <button  class="btn btn-primary disable event-team__btn-submit"  @click="handleBtnSubmit" :class="{'enable': isBtnParticipateEnable && !isEventEnded}">
                <span>{{ isJoinedEvent ? $t('SUBMITTED') : $t('SUBMIT') }}</span>
            </button>
        </div>
        <div class="event-team__term_condition btn--underline" @click="showModalTermCondition()">{{ $t('TERMS_&_CONDITIONS') }}</div>
    </div>
        <!-- sign up info -->
    <ModalPopup ref="modalPopupSignupRef" >
        <div class="display-flex-align-center flex-col modal-confirmation__body">
            <div class="mb-15rem font-m tc">{{$t('EVENT_PREDICTION_SUBMIT_INFO')}}</div>
            <div v-if="isMobile" class="w-100">
                <router-link :to="{ name: 'signup' , query: { callBackUrl: currentPathName } }"> <button class="btn w-100 mb-1rem bg-white" style="color: black">{{$t('SIGNUP')}}</button></router-link>
                 <router-link :to="{ name: 'signin' , query: { callBackUrl: currentPathName } }"><button class="btn btn-outline w-100">{{$t('LOGIN')}}</button></router-link>
            </div>
            <div v-else class="w-100">
                <button class="btn w-100 mb-1rem bg-white" @click="handleAuthentication('signUp')">{{$t('SIGNUP')}}</button>
                <button class="btn btn-outline w-100" @click="handleAuthentication('signIn')">{{$t('LOGIN')}}</button>
            </div>
        </div>
    </ModalPopup>

    <!-- confirm participate -->
    <ModalPopup ref="modalPopupConfirmParticipateRef" >
        <div class="display-flex-align-center flex-col modal-confirmation__body">
            <div clas="font-m mb-15rem tc">{{$t('CONFIRMATION_TO_PARTICIPATE')}}</div>
            <div class="w-100">            
                 <button class="btn w-100"  @click="handleParticipate">{{$t('CONFIRM')}}</button>
            </div>
        </div>
    </ModalPopup>

    <PopUpSignIndex ref="modalPopupSignInRef" :isShowWelcomeMsg=false @loginSucuessEvent="loginSucuessEvent"></PopUpSignIndex>

    <!-- term and condition desktop-->
   <ModalPopup ref="modalPopupRef" :modalSize="'contest-tnc__height'">
        <h3 class="tc mt-15rem mb-15rem">{{ $t('TERMS_&_CONDITIONS') }}</h3>
        <perfect-scrollbar class="perfect-scrollball__medium">
            <div class="term-body" v-html="contestInfo.tnc"></div>
        </perfect-scrollbar>
    </ModalPopup>
    
    <!-- term and condition mobile-->
    <MobileSlide ref="mobileSlideRef" :title = "'TERMS_&_CONDITIONS'">
        <div class="pb-1rem term-body"  v-html="contestInfo.tnc"></div>
    </MobileSlide>

</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import SocialMediaAndTermCondition from '@/components/event/SocialMediaAndTermCondition.vue'
import ModalPopup from '@/components/modal/ModalPopup.vue'
import MobileSlide from '@/components/modal/MobileSlide.vue'
import PopUpSignIndex from '@/components/user/PopUpSignInIndex.vue'
export default {
    props: {
        contestInfo: {
            type: Object
        },
        contestId: {
            type: Number,
            default: 1
        },
        isBgTransparent: {
            type: Boolean,
            default: false
        }
    },
    components: {
        SocialMediaAndTermCondition,
        ModalPopup,
        PopUpSignIndex,
        MobileSlide
    },

    data() {
        return {
            timeType: 1,

            isBtnParticipateEnable: false,

            info: {
                isAllDropdownFilled: false,
                isSocialMediaTNCTick: false
            },

            myPredictionData: [],
            currentPathName:'',

            sessionStorageKnockout: null
        }
    },
    mounted() {
        this.currentPathName = window.location.pathname;
    },
    computed: {
        ...mapGetters([              
                'isMobile',
                'currentLocale',
                'currentLanguageObj',
                'isLogin',
                'matchKnockOutsList',
                'isJoinedEvent',
                'isEventEnded'
           ]),
    },
    watch:{
        contestInfo: {
            deep:true,
	        	handler(newVal, oldVal) {
                  this.timeType = newVal.timeType;

                  let routeQuery = this.$route.query;
                  let queryObject = {};
                    if (Object.keys(routeQuery).length !== 0 && routeQuery.constructor === Object) {
                        queryObject = routeQuery;    
                        this.redirectFrom = queryObject.redirectFrom;

                        this.sessionStorageKnockout = JSON.parse(sessionStorage.getItem('worldcupKnockout'));  

                        if (this.sessionStorageKnockout !== null) {
                            this.handleBtnSubmit(); 
                        }                       
                    } 

			    }
        },
        matchKnockOutsList: {
            deep:true,
	        	handler(newVal, oldVal) {
                  this.verifyIsAllDropdownFilled();
			    }
        },
        isJoinedEvent: {
            deep: true,
                handler(newVal, oldVal) {
                    if (newVal) {
                        this.isBtnParticipateEnable = false;
                    }
                }
        }
    },

    methods: {
        ...mapActions([
            "popUpSignInModuleEvent",
            "postSubmitPrediction",
            "toastrChangeEvent",
            "getMyPrediction"
        ]),
        //show modal pop up term & condition
        showModalTermCondition() {
            if (this.isMobile) {
                this.$refs.mobileSlideRef.handleOpen();
            } else {
                 this.$refs.modalPopupRef.handleOpen();
            }
        },
        
        changeTickEvent2(result) {

            this.myPredictionData = [];
            if (this.timeType === 3 || this.isEventEnded) {
                //UPCOMING or event ended
                this.isBtnParticipateEnable = false;
            } else {
                let availSequence = [49,50,53,54,57,58,61]
                let isBtnEnable = result;
                let isError = false;

                //reset knockOutsList error
                this.matchKnockOutsList.forEach(x => {
                    x.home.hasError = false;
                    x.away.hasError = false
                });

                for (let i = 0; i<this.matchKnockOutsList.length; i++) {
                    
                    let element = this.matchKnockOutsList[i];
                    let oppositGroup;

                    if (i < this.matchKnockOutsList.length - 2) {
                        if (element.sequenceId === 61) {
                            oppositGroup = this.matchKnockOutsList[i + 1];
                        } else {
                            oppositGroup = this.matchKnockOutsList[i + 2];
                        }
                    }
                 
                    if (availSequence.indexOf(element.sequenceId) !== -1) {
                        //check error which sequenceid Is 57-64
                        if (element.home.selectedTeam.teamId === oppositGroup.home.selectedTeam.teamId) {
                            element.home.hasError = true;
                            oppositGroup.home.hasError = true;       
                            isBtnEnable = false;    
                            isError = true;
                        }

                        if (element.away.selectedTeam.teamId === oppositGroup.away.selectedTeam.teamId) {
                            element.away.hasError = true;
                            oppositGroup.away.hasError = true;
                            isBtnEnable = false;
                            isError = true;
                        }
                        
                        //check error which sequenceId is 49-56
                        if (element.home.selectedTeam.teamId === oppositGroup.away.selectedTeam.teamId) {
                            element.home.hasError = true;
                            oppositGroup.away.hasError = true;
                            isBtnEnable = false;
                            isError = true;
                        } 
                        
                        if (element.away.selectedTeam.teamId === oppositGroup.home.selectedTeam.teamId) {
                            element.away.hasError = true;
                            oppositGroup.home.hasError = true;
                            isBtnEnable = false;
                            isError = true;
                        }
                    }              

                    let myPrediction = {
                        sequenceId: element.sequenceId,
                        homeId: element.home.selectedTeam.teamId,
                        awayId: element.away.selectedTeam.teamId,
                    }

                    this.myPredictionData.push(myPrediction)
                };

                this.isBtnParticipateEnable = isBtnEnable;

                if (isError) {
                     let paramsToastr = {
                        isOpen: true,
                        msg: "CONTEST_FIFA_ERROR_MSG",
                        type: 'error',
                        duration: 5000
                    }

                    this.toastrChangeEvent(paramsToastr);
                }
            }

            this.currentPathName = `${this.currentPathName}`
            this.storeDataPath = {
                isTick: this.isBtnParticipateEnable
            }
        },
        verifyIsAllDropdownFilled() {
           
            //check if all dropdown filled is select
            this.info.isAllDropdownFilled = this.matchKnockOutsList.every(obj => {
                return obj.home.selectedTeam && obj.away.selectedTeam
            })

            if (this.info.isAllDropdownFilled) {
           
            } else {
                this.isBtnParticipateEnable = false;
            }

            // this.matchKnockOutsList.forEach(element => {
            //     if (element.home.selectedTeam && element.away.selectedTeam) {
            //         this.info.isAllDropdownFilled = true;
            //     } else {
            //         this.info.isAllDropdownFilled = false;
            //         break;
            //     }
            // });
        },
        async handleBtnSubmit() {
        
            let myPredictions = [];
           debugger;
            this.sessionStorageKnockout = JSON.parse(sessionStorage.getItem('worldcupKnockout'));  
            if (this.sessionStorageKnockout) {
                myPredictions = this.sessionStorageKnockout;
            } else {
                this.matchKnockOutsList.forEach(element => {
                    let myPrediction = {
                        sequenceId: element.sequenceId,
                        homeId: element.home.selectedTeam.teamId,
                        awayId: element.away.selectedTeam.teamId,
                    }
                    myPredictions.push(myPrediction);
                });

                sessionStorage.setItem('worldcupKnockout',JSON.stringify(myPredictions));
            }

            if (!this.isLogin) {
                //sign up confirmation
                this.$refs.modalPopupSignupRef.handleOpen();  
            } else {
                let params = {
                    contestId: this.contestId,
                    myPrediction: JSON.stringify(myPredictions)
                }

                const result = await this.postSubmitPrediction(params);
                sessionStorage.removeItem('worldcupKnockout');

                //submit sucuess
                if (result.result.businessCode === 0) {
                    
                   let redirectFrom = this.redirectFrom;

                   //show sucuessful message
                    let toastrMsg = "SUBMITTED_SUCCESSFULLY";
                    if (redirectFrom === 'signIn') {
                        toastrMsg = 'PREDICTION_SUBMITTED_SUCCESSFULLY_POPUP_SIGN_IN';
                    } else if (redirectFrom === 'signUp') {
                        toastrMsg = 'PREDICTION_SUBMITTED_SUCCESSFULLY_POPUP_SIGN_UP';
                    } else if (redirectFrom === 'forgetPassword') {
                        toastrMsg = 'PREDICTION_SUBMITTED_SUCCESSFULLY_POPUP_PASSWORD_CHANGE';
                    }

                    let paramsToastr = {
                        isOpen: true,
                        msg: toastrMsg,
                        type: ''       
                    }

                    this.toastrChangeEvent(paramsToastr);
                    // setTimeout(() => {
                    //     window.location.reload();
                    // }, 300);
              
                } else {
                    debugger;
                    let paramsToastr = {
                        isOpen: true,
                        msg:  result.result.message,
                        type: 'info'       
                    }
                    this.toastrChangeEvent(paramsToastr);
                }
                this.$emit("emitJoinEvent", 'submitSucuess')
            }
        },
         async getMyPredictionData() {
            let params = {
                contestId: this.contestId,
            }
            
            const result = await this.getMyPrediction(params);
              if (result.result.allowSubmit) {
                 this.handleBtnSubmit();
              } else {
                 let redirectFrom = this.redirectFrom;
                    //show sucuessful message
                    let toastrMsg = "SUBMITTED_SUCCESSFULLY";
                    if (redirectFrom === 'signIn') {
                        toastrMsg = 'WELCOME_BACK_YOU_HAVE_ALREADY_PARTICIPATE_IN_THIS_EVENT';
                    } else if (redirectFrom === 'signUp') {
                        toastrMsg = 'PREDICTION_SUBMITTED_SUCCESSFULLY_POPUP_SIGN_UP';
                    } else if (redirectFrom === 'forgetPassword') {
                        toastrMsg = 'PASSWORD_CHANGE_YOU_HAVE_ALREADY_PARTICIPATE_IN_THIS_EVENT';
                    }

                    let paramsToastr = {
                        isOpen: true,
                        msg: toastrMsg,
                        type: ''       
                    }

                 this.toastrChangeEvent(paramsToastr);
                this.$emit("emitJoinEvent", 'submitSucuess')
              }
         },

        loginSucuessEvent(data) {
            this.$refs.modalPopupSignInRef.handleClose();
            this.redirectFrom = data;

            this.getMyPredictionData()
         
            // this.$emit(); 
        },

        handleAuthentication(type) {
            this.popUpSignInModuleEvent(type)
            this.$refs.modalPopupSignInRef.handleOpen();
            this.$refs.modalPopupSignupRef.handleClose(); 
        },
    },
}
</script>

<style>
    .event-team__btn-submit{
        width: 21.25rem;
        margin-bottom: 1.25rem;
        display: block;
    }

    .event-team__wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-color: var(--color-theme);
        width: 49.625rem;
        padding: 1.5rem 1rem;
        border-radius: 1.5rem;
        margin: 0 auto;
        margin-bottom: 1.9375rem;
        box-sizing: border-box;
    }

    .event-team__wrapper.bg-transparent{
        background-color: transparent;
    }

    @media (max-width: 768px){
        .event-team__wrapper {
            width: 100%;
        }
        .event-team__wrapper.bg-transparent{
            margin-bottom: 0;
            padding: 0 1rem;
        }
    }
</style>