<template>
    <div class="display-flex-center scroll-from-top">
        <h4 class="prediction-record">{{$t(title)}}</h4>
    </div>   
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            defaul: ''
        }
    }
}
</script>

<style>

</style>