<template>
    <template v-if="type === 2">
        <swiper  class="relative sport168 event-banner__swiper-wrapper"  :pagination="{ clickable: true }" :autoplay='{"delay": 5000, "disableOnInteraction": false}'>                
            <swiper-slide class="event-banner__swiper "   v-for="(o, index) in bannerList"  :key="index">        
                    <img class="img-cover" :class="customClass"  :src="`${s3ImgUrl}${o}`">                 
            </swiper-slide>                             
        </swiper>
    </template>
    <template v-if="type === 1">
        <div class="event-banner__contest show scroll-from-top">
                <img class="img-cover scale-fix" :src="`${s3ImgUrl}${contestInfo.contestBanner}`">
                <div class="event-banner__contest-overlay"></div>
            </div>
            <div class="event-banner__prize scroll-from-bottom">
                <!-- grand prize banner -->
                <div class="event-banner__prize-card" v-if="contestInfo.grandPrizeImage !==''">
                    <img class="img-cover" :src="`${s3ImgUrl}${contestInfo.grandPrizeImage}`">
                    <div class="event-banner__prize-card__overlay">
                        <span v-html="contestInfo.grandPrizeHover"></span>
                    </div>
                </div>
                <!-- lucky draw prize -->
                <div class="event-banner__prize-card">
                    <img class="img-cover" :src="`${s3ImgUrl}${contestInfo.luckyDrawPrizeImage}`">
                    <div class="event-banner__prize-card__overlay">
                        <span v-html="contestInfo.luckyDrawPrizeHover"></span>
                    </div>
                </div>
            </div>
    </template>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import SwiperCore, { Pagination, Navigation, Autoplay} from 'swiper/core';
import { mapGetters, mapActions } from 'vuex'

SwiperCore.use([ Pagination, Navigation, Autoplay ]);

import config from '@/js/config.js'
export default {
    components: {
        Swiper,
        SwiperSlide,  
    },

    props: {
        type: {
            default: 1
        },
        contestInfo: {
            type: Object
        },
        customClass: {
            type: String,
        }
    },

    data() {
        return {
            s3ImgUrl: config.s3ImgUrl,
            //bannerList: []
            bannerList: []
            
        }
    },
   watch:{
        contestInfo: {
            deep:true,
	        	handler(newVal,oldVal) {
                    this.bannerList = newVal.contestBannerList;             
                }
        }
   },
   computed: {
        ...mapGetters([              
                'isMobile',
                'currentLocale',
                'currentLanguageObj',
                'isLogin',
                'matchKnockOutsList',
                'isJoinedEvent',
                'isEventEnded'
           ]),
    },
    // computed: {
    //     bannerList() {
         
    //         let bannerList = [
           
    //             {
    //                 image: this.contestInfo.grandPrizeImage,
    //                 description: this.contestInfo.grandPrizeHover
    //             },
    //             {
    //                 image: this.contestInfo.luckyDrawPrizeImage,
    //                 description: this.contestInfo.luckyDrawPrizeHover
    //             }
    //         ];
         
    //         return bannerList
    //     }
    // },
}
</script>

<style scoped>
.event-banner__swiper-wrapper{
    padding-bottom: 1.5rem;
}
.event-banner__swiper{
    height: 7.5rem;

}
.event-banner__swiper img{
    border-radius: 1rem;
    overflow: hidden;
}
.event-banner__contest{
    margin-bottom: 1rem;
    height: 11.25rem;
    border-radius: 0.5rem;
    overflow: hidden;
    position: relative;
    cursor: pointer;
}

.event-banner__prize{
    display: grid;
    grid-template-columns: repeat(2,1fr);
	column-gap: 1rem;
    height: 7.5rem;
}
.event-banner__contest.show .event-banner__contest-overlay{
    transform: translate(0,0);
}
.event-banner__contest img{
    transition: all .3s;
}
.event-banner__contest.show img{
     transform: scale(1.2);
}
.event-banner__contest-overlay{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0.5rem 0 0 1.5rem;
    font-size: 1.125rem;
    font-weight: 700;
    transform: translate(0,100%);
    transition: all .3s;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size:3.125rem;
    text-align: center;
}
.event-banner__prize-card{
    height: 100%;
    border-radius: 0.5rem;
    overflow: hidden;
    position: relative;
    cursor: pointer;
}
.event-banner__prize-card__overlay{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0.5rem 0 0 1.5rem;
    font-size: 1.125rem;
    font-weight: 700;
    transform: translate(0,100%);
    transition: all .3s;
    background-color: rgb(0 0 0 / 72%);
}
.event-banner__prize-card img{
     transition: all .3s;
}
.event-banner__prize-card:hover img{
    transform: scale(1.2);
}
.event-banner__prize-card:hover .event-banner__prize-card__overlay{
    transform: translate(0,0);
}
@media (max-width: 768px) {
    .event-banner__contest {
        height: 7.125rem;
    }
    .event-banner__prize{
        height: 5rem;
        column-gap: .5rem;
    }
    .event-banner__contest-overlay{
        font-size: 1.375rem;
        padding-left: 0.5rem;
    }
    .event-banner__prize-card__overlay{
        font-size: 0.6075rem;
        padding-left: 0.5rem;
    }
    .event-banner__swiper{
        height: 86px;
    } 
}
</style>