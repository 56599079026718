

<template>  
    <div class="drop-down-list">
        <!-- upcoming 3-->
        <template v-if="contestInfo.timeType === 3">
            <div class="drop-down-list__field no-hover">
                 <img class="drop-down-list__field__icon" src="../../../../static/images/icons/icon_bracket_default.png">
                <div class="drop-down-list__field__label">TBD</div>
                <div class="drop-down-list__field__arrow"> - </div>
            </div>
        </template>

        <!-- ongoing 2 & past 1-->
        <template v-else>
            <template v-if="isShowKnockOutLive">
                <div class="drop-down-list__field no-hover">
                    <img class="drop-down-list__field__icon" 
                        :src="status === 'home'  
                        ? knockOutData.homeId 
                        ? `${s3ImgUrl}/${knockOutData.homeLogo}`
                        : require('../../../../static/images/icons/icon_bracket_default.png')
                        : knockOutData.awayId ? `${s3ImgUrl}/${knockOutData.awayLogo}`
                        : require('../../../../static/images/icons/icon_bracket_default.png')">
                    <div class="drop-down-list__field__label">{{ status === 'home' ? knockOutData.homeId ? knockOutData.homeName : 'TBD' : knockOutData.awayId ? knockOutData.awayName : 'TBD' }}</div>
                    <div class="drop-down-list__field__arrow">
                         <div>{{ formatTeamScore(knockOutData, status) }}</div>
                         <div class="drop-down-list__penalty-wrapper" v-if="verifyToShowPenalty(knockOutData, status)">  
                            <div class="drop-down-list__penalty-divider"></div>                        
                            <div class="drop-down-list__score" :class="{'pen-win': verfiyIsPenaltyWin(knockOutData, status)}">{{ formatPenaltlyScore(knockOutData, status) }}</div>
                         </div>
                    </div>
                     
                </div>
            </template>

            <template v-else>
                <template v-if="isDisplayOnly">
                    <template v-if="isShowLiveData">
                        <div class="drop-down-list__field no-hover">
                            <img class="drop-down-list__field__icon" 
                                :src="status === 'home'  
                                ? knockOutData.homeId 
                                ? `${s3ImgUrl}/${knockOutData.homeLogo}`
                                : require('../../../../static/images/icons/icon_bracket_default.png')
                                : knockOutData.awayId ? `${s3ImgUrl}/${knockOutData.awayLogo}`
                                : require('../../../../static/images/icons/icon_bracket_default.png')">
                            <div class="drop-down-list__field__label">{{ status === 'home' ? knockOutData.homeId ? knockOutData.homeName : 'TBD' : knockOutData.awayId ? knockOutData.awayName : 'TBD' }}</div>
                            <!-- <div class="drop-down-list__field__arrow">{{ status === 'home' ? knockOutData.homeScore ? knockOutData.homeScore : '-' : knockOutData.awayScore ? knockOutData.awayScore : '-'}}</div> -->
                            <div class="drop-down-list__field__arrow">{{ formatTeamScore(knockOutData, status) }}</div>
                          
                          
                            
                        </div>
                    </template>
                    <template v-else>                  
                        <div class="drop-down-list__field no-hover" :class="{'is-correct':status === 'home' ? knockOutData.homeId === knockOutData.actualHomeId : knockOutData.awayId === knockOutData.actualAwayId }">
                            <!-- <img class="drop-down-list__field__icon" :src="`${s3ImgUrl}/${displayIcon}`"> -->
                            <img class="drop-down-list__field__icon" 
                                :src="status === 'home'  
                                ? knockOutData.homeId 
                                ? `${s3ImgUrl}/${knockOutData.homeLogo}`
                                : require('../../../../static/images/icons/icon_bracket_default.png')
                                : knockOutData.awayId ? `${s3ImgUrl}/${knockOutData.awayLogo}`
                                : require('../../../../static/images/icons/icon_bracket_default.png')">
                            <div class="drop-down-list__field__label">{{ status === 'home' ? knockOutData.homeName : knockOutData.awayName }}</div>
                            <svg class="drop-down-list__field__arrow" v-if="status === 'home' ? knockOutData.homeId === knockOutData.actualHomeId : knockOutData.awayId === knockOutData.actualAwayId"
                                width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.78922 10.88L1.61922 6.71005L0.199219 8.12005L5.78922 13.71L17.7892 1.71005L16.3792 0.300049L5.78922 10.88Z" fill="white"/>
                            </svg>
                        </div>
                    </template>
                </template>
                <template v-else>
                    <div class="drop-down-list__field" :class="{'has-error': hasError, 'drop-down-list__field--disabled': disabled, 'drop-down-list__field--active': isMenuOpen }" @click="handleDropDownListFieldClickEvent">
                        <img v-if="displayIcon" class="drop-down-list__field__icon" :src="`${s3ImgUrl}/${displayIcon}`">
                        <div  class="drop-down-list__field__label" :class="{ 'drop-down-list__field__label--no-icon': !defaultIcon }">{{ $t(defaultText) }}</div>

                        <svg class="drop-down-list__field__arrow" width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.41 0.590088L6 5.17009L10.59 0.590088L12 2.00009L6 8.00009L0 2.00009L1.41 0.590088Z" />
                        </svg>
                    </div>
                </template>
            </template>
        </template>
        
        <!-- dropdown menu -->
        <div v-if="items.length > 0 && !isDisplayOnly" class="drop-down-list__menu" :class="{ 'drop-down-list__menu--hidden' : !isMenuOpen }">
            <div v-for="(item, index) in items" :key="index" @click="handleDropDownListMenuItemSelected(item)" class="drop-down-list__menu__item drop-down-list__menu__item--hoverable">
                <img class="drop-down-list__menu__item__icon" :src="`${s3ImgUrl}/${item.logoUrl}`">
                <div class="drop-down-list__menu__item__label" :class="{ 'drop-down-list__menu__item__label--no-icon': !item.icon }">{{ item.teamName }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import config from '@/js/config.js'

const defaultTeamName = 'TBD';
const defaultTeamScore = "-";
export default {
    props: {
        hasError: {
            type: Boolean,
            default: false
        },
        status: {
            type: String,
            default: null
        },
        knockOutData: {
            type: Object,
            default: {}
        },
        contestInfo: {
            type: Object,
            default: {}
        },
        defaultText: {
            type: String,
            default: 'Select Item...'
        },
        displayIcon: {
            type: String,
            default: null
        },
        defaultIcon: {
            type: String,
            default: null
        },
        items: {
            type: Array,
            default: []
        },
        disabled: {
            type: Boolean,
            default: false
        },
        isDisplayOnly: {
            type: Boolean,
            default: false
        },
        isMyPrediction: {
            type: Boolean,
            default: false
        },
        isShowKnockOutLive: {
            type: Boolean,
            default: false
        },
        isShowLiveData: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapGetters([              
                'matchKnockOutsList',
                'isMobile'
           ]),
    },
    data() {
        return {
            s3ImgUrl:config.s3ImgUrl,

            isMenuOpen: false,
            selectedItem: null,

            selectionsAllList: null
        };
    },
    mounted() {      
        document.addEventListener('click', this.handleDocumentClickEvent);
    },
    methods: {
         ...mapActions([
            "getSelections",
        ]),
        verifyPenaltyWinner(o) {
            let status =  this.status;
            if (status === 'home') {
                if (o.homePenaltyScore > o.awayPenaltyScore) {
                    return true;
                } else {
                    return false;
                }
            } else {
                   if (o.awayPenaltyScore > o.homePenaltyScore) {
                    return true;
                } else {
                    return false;
                }
            }
        },

        handleDocumentClickEvent(e) {
            if (!this.$el.contains(e.target)) {
                this.isMenuOpen = false;
                e.stopPropagation();
            }
        },
        handleDropDownListFieldClickEvent(e) {
            //bottom dropdown change position list
            const btmDropdownList = [56, 60, 62];

            let topPosition;

            if (this.isMobile) {
                if (btmDropdownList.includes(this.knockOutData.sequenceId) ) {

                    if (this.knockOutData.sequenceId !== 56) {
                        topPosition = "-5.5rem"
                    } else {
                        topPosition = "-7.5rem" 
                    }

                    e.currentTarget.nextElementSibling.style.top = topPosition
                }
            }
            if (!this.disabled) {
                this.isMenuOpen = !this.isMenuOpen;
            }
        },
        handleDropDownListMenuItemSelected(item) {
            this.items.forEach(x => x.selected = false);
            item.selected = true;

            this.selectedItem = item;
            this.isMenuOpen = false;

            this.knockOutData[this.status][`selectedTeam`] = item;
            this.knockOutData[this.status]['displayTeamName'] = item.teamName;
            this.knockOutData[this.status]['displayIcon'] = item.logoUrl;

            this.knockOutData.status = this.status;
            this.$emit('emitEvent', this.knockOutData)
        },

        formatPenaltlyScore(entry, status) {
            if (status === 'home') {
                if (entry.homeScore) {
                    return entry.homePenaltyScore
                } else {
                    return defaultTeamScore
                }
            } else {
                 if (entry.awayScore) {
                    return entry.awayPenaltyScore
                } else {
                    return defaultTeamScore
                }
            } 
        },

        formatTeamScore(entry, status) {
            if (status === 'home') {
                if (entry.homeScore) {
                    return entry.homeScore
                } else {
                    return defaultTeamScore
                }
            } else {
                 if (entry.awayScore) {
                    return entry.awayScore
                } else {
                    return defaultTeamScore
                }
            } 
        },

         verfiyIsPenaltyWin(entry, status) {
            if (status === 'home') {
                if (entry.homePenaltyScore > entry.awayPenaltyScore) {
                    return true
                } else  {
                    return false
                }
            } else {
                if (entry.awayPenaltyScore > entry.homePenaltyScore) {
                    return true
                } else {
                    return false
                }
            }
        },

        verifyToShowPenalty(entry, status) {
            if (status === 'home') {
                if (entry.homePenaltyScore) {
                    return true
                } else {
                    return false
                }
            } else {
                if (entry.awayPenaltyScore) {
                    return true
                } else {
                    return false
                }
            }
        }
    }
};
</script>
<style scoped>

.knockout-card {
    position: absolute;
    width: 160px;
    height: 32px;
}
.drop-down-list {
    position: relative;
    font-family: 'OpenSans';
    font-style: normal;
    font-weight: 400;
    user-select: none;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: .25rem;
}
.drop-down-list__field__wrapper{
    position: absolute;
    right: 9px;
    top: 9.5px;
    height: 13px;
    box-sizing: border-box;
    fill: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.6875rem;
}
.drop-down-list__field__seperator{
    width: 1px;
    /* height: .7rem; */
    background-color: #4B616C;
}
.drop-down-list__field__score{

}
.drop-down-list__penalty-wrapper{
    display: flex;
}
.drop-down-list__penalty-divider{
    width: 1px;
    height: 18px;
    background-color: #4B616C;
    margin: 0 4px 0 5px;
}
.drop-down-list__score{
    line-height: 17px;
    width: 13px;
    text-align: center;
}
.pen-win{
    background: #5CD66C;
    padding: 1px 3px;
    box-sizing: border-box;
    border-radius: 0.25rem;
    color: black;
    font-weight: 700;
}
.drop-down-list__field {
    position: relative;
    height: 32px;
    width: 100%;
    background: #35454D;
    border-radius: 4px;
    box-sizing: border-box;
    color: #CFD9DE;
    cursor: pointer;
    transition: box-shadow linear 0.1s;
    display: flex;
    align-items: center;
    padding: 0 0.5625rem;
}
.drop-down-list__field.is-correct{
    background-color: #2E8FEF;
}
.drop-down-list__field.has-error{
     border: 1px solid red;   
}
.drop-down-list__field--active,
.drop-down-list__field:not(.drop-down-list__field--disabled):hover {
    /* border: 2px solid #2e8fef; */
    box-shadow: inset 0px 0px 0px 2px #2e8fef;
}
.drop-down-list__field.no-hover{
    cursor: unset;
}
.drop-down-list__field.no-hover:hover{
    box-shadow: none;
}
.drop-down-list__field--disabled {
    color: #4b616c;
    cursor: not-allowed;
}

.drop-down-list__field--disabled>.drop-down-list__field__arrow {
    fill: #4b616c;
}

.drop-down-list__field__icon {
    margin-right: 0.5rem;
    width: 0.8125rem;
    -o-object-fit: contain;
    object-fit: contain;
}

.drop-down-list__field__label {
    font-size: 11px;
}

.drop-down-list__field__label--no-icon {
    left: 8px;
}

.drop-down-list__field__arrow {
    position: absolute;
    right: 12px;
    top: 9.5px;
    height: 13px;
    box-sizing: border-box;
    fill: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.6875rem;
}

.drop-down-list__menu {
    position: absolute;
    color: black;
    padding: 8px 0px;
    width: 100%;
    height: auto;
    background: #ffffff;
    box-shadow: 0px 64px 64px rgba(0, 0, 0, 0.08), 0px 32px 32px rgba(0, 0, 0, 0.08), 0px 16px 16px rgba(0, 0, 0, 0.08), 0px 1px 1px rgba(0, 0, 0, 0.08), 0px 2px 2px rgba(0, 0, 0, 0.08), 0px 4px 4px rgba(0, 0, 0, 0.08), 0px 8px 8px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    box-sizing: border-box;
    margin-top: 0;
    z-index: 9999;
    transition: opacity linear 0.1s;
    transition: all .3s;
    display: block;
}
.drop-down-list__menu--hidden {
    opacity: 0;
    pointer-events: none;
    height: 0;
    display: none;
}

.drop-down-list__menu__item {
    position: relative;
    padding: 12px 0px 12px 8px;
    width: 100%;
    height: 32px;
    background: #ffffff;
    color: #212B30;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.drop-down-list__menu__item--hoverable:hover {
    background: #5c7784;
    color: #ffffff
}


.drop-down-list__menu__item--selected {}


.drop-down-list__menu__item__icon {
    margin-right: .5rem;
    width: 0.8125rem;
    object-fit: contain;
}

.drop-down-list__menu__item__label {

    font-size: 11px;
    line-height: 13px;
  
}

.drop-down-list__menu__item__label--no-icon {
    left: 8px;
}
</style>
